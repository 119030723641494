import React, { useEffect, useState } from 'react';
import '../style/allpopUps.css';
import axios from "../../config/axios";
// components 
import { RxCross1 } from "react-icons/rx";
import { v4 as uuid } from 'uuid';  
function getDate() {
  const today = new Date();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const date = today.getDate();

  let hours = today.getHours();
  const minutes = today.getMinutes().toString().padStart(2, '0');
  const seconds = today.getSeconds().toString().padStart(2, '0');

  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  const showTime = `${hours}:${minutes}:${seconds} ${ampm}`;

  return `${date}/${month}/${year} , ${showTime}`;
}

const CreateOrEdit  = ({ showModal, closeModal,createORedit , editImage }) => {
  const [currentDate] = useState(getDate());
  const [formData, setFormData] = useState({
    imageId: '',
    type: '',
    imageUrl:'',
    BulkImageUrls: '',
    dateTime: ''
  });
  const [isBulkCreate, setIsBulkCreate] = useState(false);


  useEffect(() => {
    if (createORedit === 'edit' && editImage) {
      setFormData(editImage);
    } else {
      setFormData({
        imageId: '',
        type: '',
        imageUrl: '',
        BulkImageUrls: '',
        dateTime: ''
      });
    }
  }, [createORedit, editImage]);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.type === '') {
      alert('Please select a type');//do other then alert
      return;
    }

    if (isBulkCreate) {
      if (formData.BulkImageUrls === '') {
        alert('Please enter bulk image URLs');//do other then alert
        return;
      }
      const urls = formData.BulkImageUrls.split('\n').filter(url => url.trim() !== '');
      for (const url of urls) {
        const data = {
          imageId: uuid(),
          type: formData.type,
          imageUrl: url,
          dateTime: currentDate
        };

        try {
          const res = await axios.post("/gallery", data);
          console.log(res.data);
        } catch (error) {
          alert(error.message);
        }
      }
    } else {
      if (formData.imageUrl === '') {
        alert('Please enter image URL');//do other then alert
        return;
      }

      const data = {
        imageId: formData.imageId || uuid(),
        type: formData.type,
        imageUrl: formData.imageUrl,
        dateTime: currentDate
      };

      try {
        if (createORedit === 'edit') {
          const res = await axios.put(`/gallery/${formData.imageId}`, data);
          // alert(res.data.message); //do other then alert
        } else {
          const res = await axios.post("/gallery", data);
          // alert(res.data.message);//do other then alert
        }
      } catch (error) {
        alert(error.message);
      }
    }

    closeModal();
    setFormData({
      imageId: '',
      type: '',
      imageUrl: '',
      BulkImageUrls: '',
      dateTime: ''
    });

  };

  return (
    <>
      {showModal && (
        <div className='gal-pop-container'>



          <form className="admin-gal-form" onSubmit={handleSubmit}>
            {createORedit=='create'?<h3>Add New Images</h3>: <h3>Edit Image</h3>}
            <div className="flex-center close-gal-pop pointer" onClick={closeModal}>
              <RxCross1 />
            </div>
            <div className="admin-form-group">
              <label>Type:</label>
              <select name="type" value={formData.type} onChange={handleChange}>
                <option value="">Select</option>
                <option value="Main Gallery">Main Gallery</option>
                <option value="Room Gallery">Room Gallery</option>
                <option value="Food Gallery">Food Gallery</option>
                <option value="Place Gallery">Place Gallery</option>
              </select>
            </div>
            {!isBulkCreate && (
              <div className="admin-form-group">
                <label>Image URL:</label>
                <input
                  autoComplete="off"
                  type="text"
                  name="imageUrl"
                  value={formData.imageUrl}
                  onChange={handleChange}
                />
              </div>
            )}
            {createORedit=='create' &&
            (
              isBulkCreate && (
              <div className="admin-form-group">
                <label>Bulk Image URLs:</label>
                <textarea
                  name="BulkImageUrls"
                  value={formData.BulkImageUrls}
                  onChange={handleChange}
                />
              </div>
            )
          )
            
          }
          {createORedit=='create'?
            <div className="admin-checkbox mb-5">
              <span>For Bulk Image URLs:</span>
              <input
                type="checkbox"
                checked={isBulkCreate}
                onChange={() => setIsBulkCreate(!isBulkCreate)}
              />
            </div>
            :null
          }

            <button className='green-btn' type="submit">{createORedit === 'edit' ? 'Save Changes' : 'Create'}</button>
          </form>
        </div>
      )}
    </>
  );
};

export default CreateOrEdit ;
