import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../style/bookingformpage.css";
import axios from "../config/axios";
import {  useNavigate } from "react-router-dom";

import { BiMaleFemale } from "react-icons/bi";
import { FaBed } from "react-icons/fa";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {CircularProgress,} from '@mui/material';
// components 
import Navbar from "../components/sidepagesnavbar"
import DatePicker from "../components/datepicker"
import Humour from "../components/booking_humour"
import Footer from '../components/Pagefooter'
import Popup_alert_small from '../components/popup_alert_small'
import PaymentApologyModal from '../components/apologyModal'
import ConfirmationModal from '../components/bookingConfirmPopup'

// redux 
import { useSelector, useDispatch } from 'react-redux'
import { from, to ,daydiff } from '../stateManager/reducers/dateslice'
import { payOnline } from '../stateManager/reducers/payslice'
import { fetchAvailableRooms } from '../stateManager/reducers/availablityslice'

// generate uniqueid 
import { v4 as uuid } from "uuid";


function getDate() {
  const today = new Date();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const date = today.getDate();

  let hours = today.getHours();
  const minutes = today.getMinutes().toString().padStart(2, '0');
  const seconds = today.getSeconds().toString().padStart(2, '0');

  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  const showTime = `${hours}:${minutes}:${seconds} ${ampm}`;

  return `${date}/${month}/${year} , ${showTime}`;
}


const validationSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(3, "too short")
    .required("*required"),
  lastname: Yup.string()
    .min(3, "too short")
    .required("*required"),
  phone: Yup.string()
    .min(10, "invalid phone number")
    .max(12, "invalid phone number")
    .required("*required")
    .matches(/^[0-9]+$/, "invalid phone number"),

  zip: Yup.string()
    .min(4, "invalid zip code")
    .max(6, "invalid zip code")
    .matches(/^[0-9]+$/, "enter correct code")
    .required("*required"),
    
  total_persons: Yup.string()
    .min(1, "must have one ")
    .max(2, "its too much")
    .matches(/^[0-9]+$/, "invalid number")
    .required("*required"),
  total_rooms: Yup.string()
    .min(1, "*required")
    .max(1, "out of rooms")
    .matches(/^[0-9]+$/, "invalid number")
    .required("*required"),


  address: Yup.string()
    .min(5, "please enter full address ")
    .required("*required"),
  email: Yup.string()
    .email("Invalid email")
    .required("*required")
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      "Invalid email address"
    ),

});


function BookingScreen({contactDetails,homepageData}) {
  // router dom 
  let navigate = useNavigate();

  // redux 
  const dater = useSelector((state) => state.datePicker.value)
  const datediff = useSelector((state) => state.datePicker.diff)
  const totalnight = useSelector((state) => state.datePicker.totalNight)
  const blockedDates = useSelector((state) => state.datePicker.blockedDates);
  const dateSliceError = useSelector((state) => state.datePicker.error);
  const ispayOnline = useSelector((state) => state.paySlice.payOnline )
  const availableRoomsType = useSelector((state) => state.availableSlice.availableRoomsType)
  const dispatch = useDispatch()

  // Check if availableRoomsType is not empty
  const roomTypesArray = Object.entries(availableRoomsType);

  // stats 
  let [Roomtype , setRoomtype]= useState('Double')
  const [isSmallAlertOpen, setisSmallAlertOpen]=useState(false)
  const [SmallAlertMessage, setSmallAlertMessage]=useState("error !")
  const [title, setTitle] = useState("Book now");
  const [loading,setLoading] = useState(false)
  const [countryCode ,setCountryCode] = useState('+91')
  const [QueryDashboardData ,setQueryDashboardData] = useState()
  const [currentDate] = useState(getDate());
  const [bookingLoading, setBookingLoading] = useState(false)
  //for  modal 
  const [ConfirmationmodalOpen, setConfirmationModalOpen] = useState(false);
  const [bookingDetails, setBookingDetails] = useState(null)
  const [ApologymodalOpen, setApologyModalOpen] = useState(false);
  // apology modal for payment option 
  const ApologyHandleOpen = () => setApologyModalOpen(true);
  const ApologyHandleClose = () => setApologyModalOpen(false);
  //confirm modal
  const  ConfirmationHandleClose = () => {
    setConfirmationModalOpen(false);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  const  ConfirmationHandleOpen = (data) => {
    setConfirmationModalOpen(true);
    setBookingDetails(data)
  };

  // country code   
  const countryCodeOptions = [
    "+91",   //india
    "+1",   // United States
    "+44",  // United Kingdom
    "+61",  // Australia
    "+86",  // China
    "+81",  // Japan
    "+65",  // Singapore
  ];

// updata stat with real value from database
const fetch = async () => {
  try {
    setLoading(true)
    const response = await axios.get('/dashboard'); 
    setQueryDashboardData(response.data[0]); // Set the data
    setLoading(false)
  } catch (error) {
    console.error("Error fetching dashboard data", error);
    setLoading(false)
  } 
};

useEffect(() => {
  fetch();
  dispatch(fetchAvailableRooms())
}, []);


// to hide error message after 7 seconds
useEffect(() => {
  if (isSmallAlertOpen){
    setTimeout(() => {
      setisSmallAlertOpen(false)
    }, 7000); // show alert for 7 seconds
  }
  
}, [isSmallAlertOpen]);



// bookingcheckBox status
const checkUncheck=(e)=>{
    let isChecked = e.target.checked;

    dispatch(payOnline(!isChecked))

    // do whatever you want with isChecked value
  }

// set respective countryCode
const handleCountryCodeChange = (e) => {
  setCountryCode(e.target.value);
}

// select room type 
const handleRoomtype = (e)=>{
  setRoomtype(e.target.value)
  // console.log(availableRoomsType)
  // console.log(e.target.value)
}

  
  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      zip: "",
      address: "",
      total_persons: "2",
      total_rooms:"1",
    },
    validationSchema, // Pass the validation schema to useFormik
    onSubmit: async (values, { setSubmitting }) => {
      // Form submission logic
      try {
        if(!dateSliceError){
          if(values.total_persons>0 && values.total_rooms>0){
            if (values.total_rooms <= QueryDashboardData.availableRooms){
              if (values.total_persons<=(QueryDashboardData.availableRooms * QueryDashboardData.allowedAdultsMultipleOf)){
                if (values.total_persons<=(values.total_rooms * QueryDashboardData.allowedAdultsMultipleOf)){

                  // must 
                  let booking_data={
                    bookingId: uuid(),
                    firstname: values.firstname,
                    lastname: values.lastname,
                    contact:{

                      phone: countryCode +" "+ values.phone,
                      email: values.email
                    },
                    zipCode: values.zip,
                    address: values.address,
                    totalPersons: values.total_persons,
                    totalRooms: values.total_rooms,
                    checkIn: dater[0],
                    checkOut: dater[1],
                    payOnline: ispayOnline,
                    roomType: Roomtype,
                    totalDay: datediff,
                    totalNight:totalnight,
                    bookedOn:currentDate,
                    ratesPerNight:QueryDashboardData.availableRoomsTypeWithRates[Roomtype],
                    totalPayableAmount:QueryDashboardData.availableRoomsTypeWithRates[Roomtype]* totalnight *values.total_rooms,
                    status:"pending" //confirmed , pending , cancelled
                  }
                  
                  if (datediff>0){
                    // console.log(booking_data)
                    dispatch(daydiff()) //for cross check

                          if (ispayOnline){
                            ApologyHandleOpen() //open apology modal
                          }
                          else{
                            // navigate("confirmed")
                            // send booking to server 
                            try {
                              setBookingLoading(true)
                              let res=await axios.post("/bookings",booking_data)
                              if (res.status===200){
                                let email_response=await axios.post("/send-email",booking_data)
                                if (email_response.status===201){
                                  // make dates to initial state
                                  dispatch(from(null))
                                  dispatch(to(null))
                                  dispatch(daydiff())
                                  
                                  setBookingLoading(false)
                                  //open confirm booking modal 
                                  ConfirmationHandleOpen(booking_data)  
                               

                        
                                }
                              }
                            } catch (error) {

                              setisSmallAlertOpen(true)
                              setSmallAlertMessage(`error! ${error.response.data.message}`)
                              setBookingLoading(false)
                            }
                            
                          }
                          
                  }
                  else{
                    setisSmallAlertOpen(true)
                    setSmallAlertMessage("Please select checkIn and checkOut properly :)")
                
                  }
                } 
                else{
                  setSmallAlertMessage(`can't stay more than ${values.total_rooms * QueryDashboardData.allowedAdultsMultipleOf} persons in ${values.total_rooms} room`)
                  setisSmallAlertOpen(true)

                }

              } 

              else{
                setSmallAlertMessage(`Only ${QueryDashboardData.availableRooms * QueryDashboardData.allowedAdultsMultipleOf} beds available `)
                setisSmallAlertOpen(true)

              }
            }
            else{
              setSmallAlertMessage(`Total  ${QueryDashboardData.availableRooms} rooms available`)
              setisSmallAlertOpen(true)
            }}
      
          else{
            setSmallAlertMessage(`error! type valid person or room `)
            setisSmallAlertOpen(true)
          }

      }
      else {
        setSmallAlertMessage(`error! From : ${dater[0]} To : ${dater[1]} how?  `)
        setisSmallAlertOpen(true)
      }

        setSubmitting(false); // You need to manually set isSubmitting to false when done
      } catch (error) {
        console.log(error.message);
      }
    },
  });


  // const handleChange = (event) => {
  //   const name = event.target.name;
  //   const value = event.target.value;
  //   setInputs((values) => ({ ...values, [name]: value }));
  // };
  // if(loading) return `

  return (
    <HelmetProvider>
    <>
    <Helmet>
    <title>{title ? title : "Heritage Haveli Alsisar"}</title>
    <link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"
    />

</Helmet>

    
    <div className="bookingformpage App-controller">

    <div className="super-container">
      <Navbar/>

       {/* <p>availableRooms :  {QueryDashboardData ? QueryDashboardData.availableRooms : ""}</p> */}
      
       <Humour homepageData={homepageData}/>

       {/* <div>
            <h3>All Errors:</h3>
            <pre>{JSON.stringify(formik.errors, null, 2)}</pre>
          </div> */}
             <Popup_alert_small Open={isSmallAlertOpen} Close={()=>{setisSmallAlertOpen(false)}} Message={SmallAlertMessage}/>
       <br />
        <div className="date">

       <div className="from ">
        
        
        <span className="tit"> From :</span>
        <DatePicker placeholder="CheckIn" value="from"/>
      

        </div>
        <div className="to">
        <span className="tit"> To :</span>
        <DatePicker placeholder="CheckOut" value="to"/>

        </div>

        </div>
 

        <h3 className="mb-3 myfont">Enter Details</h3>
  
      <form className="" noValidate onSubmit={formik.handleSubmit}>

      
      {/* <div class="form-floating">
      <select class="form-select custom-outer medium-field" id="floatingSelect" aria-label="Floating label select example">
      <option value="1">Double Room</option>
      <option value="2">Triple Room</option>
      </select>

      </div> */}

        <div className="container  mb-3 py-5">
          
        {/* first\last  name  */}
        <div className="form-row">
          <div className="col-md-4 mb-3 medium-field">
            <label htmlFor="validationDefault01">First name</label>
            <input

              type="text"
              className="form-control custom-outer"
              id="firstname"
              placeholder="First name"
              required
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstname}
            />
            {formik.touched.firstname && formik.errors.firstname ? (
              <div style={{
                color: "red",
              }}>{formik.errors.firstname}</div>
            ) : null}
          </div>
          <div className="col-md-4 mb-3 medium-field">
            <label htmlFor="validationDefault02">Last name</label>
            <input
              type="text"
              className="form-control custom-outer"
              id="lastname"
              placeholder="Last name"
              required
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastname}
            />
            {formik.touched.lastname && formik.errors.lastname ? (
              <div style={{
                color: "red",
              }}>{formik.errors.lastname}</div>
            ) : null}
          </div>
        </div>
        {/* total room/persons  */}
        <div className="form-row">
          <div className="col-md-4 mb-3 medium-field">
            <label htmlFor="validationDefaultUsername">Total Persons</label>
            <div className="input-group">
              <div className="input-group-prepend ">
                <span className="input-group-text custom-outer" id="inputGroupPrepend2">
                <BiMaleFemale />
                </span>
              </div>
              <input
                type="number"
                min="1"
                max="60"
                className="form-control custom-outer"
                id="total_persons"
                aria-describedby="inputGroupPrepend2"
                required
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.total_persons}
              />
            
            </div>
              {formik.touched.total_persons && formik.errors.total_persons ? (
                <div style={{
                  color: "red",
                }}>{formik.errors.total_persons}</div>
              ) : null}

            
          </div>
          <div className="col-md-4 mb-3 medium-field">
            <label htmlFor="validationDefaultUsername">Total Rooms</label>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text custom-outer" id="inputGroupPrepend2">
                <FaBed />
                </span>
              </div>
              <input
                type="number"
                min="1"
                max="5"
                className="form-control custom-outer"
                id="total_rooms"
                aria-describedby="inputGroupPrepend2"
                required
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.total_rooms}
              />
            </div>
              {formik.touched.total_rooms && formik.errors.total_rooms ? (
                <div style={{
                  color: "red",
                }}>{formik.errors.total_rooms}</div>
              ) : null}
          </div>
   
        </div>

        {/* phone /email  */}
        <div className="form-row">
          {/* phone  */}
        <div className="col-md-4 mb-3 medium-field">
            <label htmlFor="validationDefaultUsername">Phone</label>
            <div className="input-group">
                <div className="input-group-prepend">
                <select
                  className="form-control custom-outer "
                  id="countryCode"
                  onChange={handleCountryCodeChange}
                  value={countryCode}
                  name="countryCode"
                  required
                >
                  {countryCodeOptions.map((code) => (
                    <option key={code} value={code}>{code}</option>
                  ))}
                </select>

              </div>
              <input
                type="text"
                className="form-control custom-outer"
                id="phone"
                aria-describedby="inputGroupPrepend2"
                placeholder="phone no."
                required
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phone}
              />
            </div>
              {formik.touched.phone && formik.errors.phone ? (
                <div style={{
                  color: "red",
                }}>{formik.errors.phone}</div>
              ) : null}
          </div>
          {/* email  */}
          <div className="col-md-4 mb-3 medium-field">
            <label htmlFor="validationDefault03">Email</label>
            <input
              type="text"
              className="form-control custom-outer"
              id="email"
              placeholder="example@gmail.com"
              required
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email ? (
              <div style={{
                color: "red",
              }}>{formik.errors.email}</div>
            ) : null}
          </div>
 
        </div>
        {/* address/zip  */}
        <div className="form-row">
        <div className="col-md-4 mb-3 medium-field">
            <label htmlFor="validationDefault03">Address</label>
            <input
              type="text"
              className="form-control custom-outer"
              id="address"
              placeholder="address"
              required
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.address}
            />
            {formik.touched.address && formik.errors.address ? (
              <div style={{
                color: "red",
              }}>{formik.errors.address}</div>
            ) : null}
          </div>
          <div className="col-md-4 mb-3 medium-field">
            <label htmlFor="validationDefault05">Zip</label>
            <input
              type="text"
              className="form-control custom-outer"
              id="zip"
              placeholder="Zip"
              required
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.zip}
            />
            {formik.touched.zip && formik.errors.zip ? (
              <div style={{
                color: "red",
              }}>{formik.errors.zip}</div>
            ) : null}
          </div>


        </div>
        {/* room type  */}
        <div className="form-row">
        <div className="col-md-4 mt-3 medium-field">
        <select
        className="form-select custom-outer form-control"
        id="floatingSelect"
        aria-label="Floating label select example"
        value={Roomtype}
        onChange={e => handleRoomtype(e)}
      >

      {roomTypesArray.length > 0 ? (
        roomTypesArray.map(([roomType, price]) => (
          <option key={roomType} value={roomType}>
            {roomType} - ₹{price}
          </option>
        ))
      ) : (
        <option value="" disabled>
          No rooms available
        </option>
      )}


        {/* <option value="Double">Double Room</option>
        <option value="Triple">Triple Room</option> */}
      </select>

        </div>

    
        
        </div>

        </div>

        <div className="form-check">
        <input className="form-check-input" onChange={e => checkUncheck(e)} type="checkbox" value="" id="flexCheckDefault"/>
        <label className="form-check-label" htmlFor="flexCheckDefault">
          <span className="ml-3 ">{QueryDashboardData ? QueryDashboardData.bookingCheckboxString : ""} </span>
       
        </label>
        </div>

             <button className="subBtn" type="submit" disabled={formik.isSubmitting || Object.keys(formik.errors).length > 0}>    
             {bookingLoading ? <CircularProgress size={24} sx={{color:"white"}} /> : 'Next'}
           </button>
     
      </form>

      <Footer contactDetails={contactDetails}/>
    </div>
    </div>

     {/* apology modal for payment option       */}
    <PaymentApologyModal open={ApologymodalOpen} handleClose={ApologyHandleClose}/>

    <ConfirmationModal open={ConfirmationmodalOpen} handleClose={ConfirmationHandleClose} bookingDetails={bookingDetails}  />

    </>
    </HelmetProvider>
    
  );
  
}

export default BookingScreen;


