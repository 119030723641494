import "../style/admin_gallery.css";
import axios from "../../config/axios";
import React, { useEffect, useState } from "react";
// components
import { CiCirclePlus } from "react-icons/ci";
import { MdDeleteOutline } from "react-icons/md";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import GalleryModalPopup from "../admin_components/gal_create_edit_popup";
import AskConfirm from "../admin_components/confirmation_modal_popup";

function GalleryData() {
  const [showModal, setShowModal] = useState(false);
  const [createORedit, setcreateORedit] = useState("create");
  const [editImage, setEditImage] = useState({
    imageId: "",
    type: "",
    imageUrl: "",
    dateTime: "",
  });
  const [filter, setFilter] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [Selected, setSelected] = useState([]);
  const [showDeleteConfirm, setshowDeleteConfirm] = useState(false);
  const [deleteId, setdeleteId] = useState(null);
  const [bulkDelete, setBulkDelete] = useState(false); // New state to handle bulk delete

  const fetchGalleryData = async () => {
    try {
      const response = await axios.get("/gallery");
      setData(response.data);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };
  // fetch data on load
  useEffect(() => {
    fetchGalleryData();
  }, []);

  // handle checkbox for switch between bulk image and single image /imageUrl
  const handleCheckboxChange = (event, id) => {
    if (event.target.checked) {
      setSelected((prevSelected) => [...prevSelected, id]);
    } else {
      setSelected((prevSelected) => prevSelected.filter((item) => item !== id));
    }
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  // to show confirm modal popup before bulk delete
  const handleDeleteConfirmModelPopUp = () => {
    setshowDeleteConfirm(!showDeleteConfirm);
  };

  // to save inline delete id
  const handleSaveDeleteId = (id) => {
    setdeleteId(id);
    setBulkDelete(false); // Indicate it's a single delete
    setshowDeleteConfirm(true);
  };

  // delete image after confirmation in row delete
  const handleDeleteAfterConfirm = () => {
    setshowDeleteConfirm(false);
    handleDelete(deleteId);
  };

  // bulk delete handle
  const handleBulkDelete = () => {
    setBulkDelete(true); // Indicate it's a bulk delete
    setshowDeleteConfirm(true);
  };

  // confirm bulk delete
  const confirmBulkDelete = () => {
    Selected.forEach((id) => {
      handleDelete(id);
    });
    setSelected([]); // Clear selected items after deletion
    setshowDeleteConfirm(false);
  };

  // handle each row delete
  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`/gallery/${id}`);
      setData((prevData) => prevData.filter((item) => item.imageId !== id)); // Update state to remove the deleted item
    } catch (error) {
      console.error("Error deleting gallery item:", error);
      setError(error);
    }
    setdeleteId(null); // Clear id after deletion
  };

  // handle each row edit
  const handleEdit = async (id) => {
    const itemToEdit = data.find((item) => item.imageId === id);
    if (itemToEdit) {
      setEditImage({
        imageId: itemToEdit.imageId,
        type: itemToEdit.type,
        imageUrl: itemToEdit.imageUrl,
        dateTime: itemToEdit.dateTime,
      });
      setcreateORedit("edit");
      setShowModal(!showModal);
    }
  };

  // handle create/add more images with plus button
  const handlePlusIconClicked = () => {
    setcreateORedit("create");
    setEditImage({
      imageId: "",
      type: "",
      imageUrl: "",
      dateTime: "",
    });
    setShowModal(!showModal);
  };

  //close gal modal for create/edit
  const CloseGalleryCreateModel = () => {
    setShowModal(false);
    // update gallery data instantly without reload page
    fetchGalleryData();
  };

  // filter data as needed
  const filteredData = filter
    ? data.filter((item) => item.type === filter)
    : data;

  return (
    <div>
      <div className="flex-center admin-small-nav">
        <div
          className="gal-edit-btn pointer"
          data-value="create"
          onClick={handlePlusIconClicked}
        >
          <CiCirclePlus />
        </div>
        {Selected.length > 0 && (
          <div className="gal-edit-btn pointer" onClick={handleBulkDelete}>
            <MdDeleteOutline />
          </div>
        )}
        <div className="gal-edit-btn">
          <select
            value={filter}
            onChange={handleFilterChange}
            className="filter-select"
          >
            <option value="">All Types</option>
            <option value="Main Gallery">Main Gallery</option>
            <option value="Room Gallery">Room Gallery</option>
            <option value="Food Gallery">Food Gallery</option>
            <option value="Place Gallery">Place Gallery</option>
          </select>
        </div>
      </div>

      <div className="main-admin-gal-data-container">
        <div className="mycontainer">
          <div className="table-container">
            <table className="styled-table">
              <thead>
                <tr>
                  <th className="table-header">Select</th>
                  <th className="table-header">Index</th>
                  {/* <th className="table-header">ID</th> */}
                  <th className="table-header">Preview</th>
                  <th className="table-header">Type</th>
                  <th className="table-header">URL</th>
                  <th className="table-header">Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((item, index) => (
                  <tr className="table-row" key={item.imageId}>
                    <td className="table-cell" data-label="Select">
                      <input
                        className="checkbox"
                        type="checkbox"
                        checked={Selected.includes(item.imageId)}
                        onChange={(event) =>
                          handleCheckboxChange(event, item.imageId)
                        }
                      />
                    </td>
                    <td className="table-cell" data-label="Index">
                      {index+1}
                    </td>
                    {/* <td className="table-cell" data-label="ID">
                      {item.imageId}
                    </td> */}
                    <td className="table-cell" data-label="Preview">
                      <a href={item.imageUrl} target="_blank" rel="noopener noreferrer">

                      <img width={50} target='_blank' src={item.imageUrl} alt={item.imageId} />
                      </a>
                    </td>
                    <td className="table-cell" data-label="Type">
                      {item.type}
                    </td>
                    <td className="table-cell" data-label="URL">
                      {item.imageUrl}
                    </td>
                    <td className="table-cell" data-label="Action">
                      <Stack spacing={2} direction="row">
                        <Button
                          className="no-outline"
                          variant="outlined"
                          onClick={() => handleEdit(item.imageId)}
                        >
                          Edit
                        </Button>
                        <Button
                          className="no-outline"
                          color="error"
                          variant="outlined"
                          onClick={() => handleSaveDeleteId(item.imageId)}
                        >
                          Delete
                        </Button>
                      </Stack>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <GalleryModalPopup
        showModal={showModal}
        editImage={editImage}
        createORedit={createORedit}
        closeModal={CloseGalleryCreateModel}
      />
      <AskConfirm
        show={showDeleteConfirm}
        onClose={handleDeleteConfirmModelPopUp}
        onConfirm={bulkDelete ? confirmBulkDelete : handleDeleteAfterConfirm}
        title={bulkDelete ? "Confirm Bulk Delete" : "Confirm Delete"}
        message={
          bulkDelete
            ? "Are you sure to delete selected images?"
            : "Are you sure to delete this image?"
        }
      />
    </div>
  );
}

export default GalleryData;
