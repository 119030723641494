import React, { useState, useEffect, useCallback,useRef  } from 'react'
import "../style/ImageGrid.css"
import { GoScreenFull } from "react-icons/go";
import { BsFullscreenExit } from "react-icons/bs";
import { RxCross1 } from "react-icons/rx";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { MdOutlineArrowForwardIos } from "react-icons/md";
// components 
import Loading ,{MiniLoading}from './loading';

// Utility functions
const isIOS = () => {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
};

const isMac = () => {
  return /Macintosh/.test(navigator.userAgent);
};


const ImageGrid = ({ images}) => {

const [isFullScreen, setIsFullScreen] = useState(false);
const [imageLoaded , setimageLoaded] = useState(false);
const is_IOS_MAC = isIOS(); //can do isIOS() || isMac() for both 

const enterFullScreen = () => {
if (document.documentElement.requestFullscreen) {
    document.documentElement.requestFullscreen();
} else if (document.documentElement.mozRequestFullScreen) { // Firefox
    document.documentElement.mozRequestFullScreen();
} else if (document.documentElement.webkitRequestFullscreen) { // Chrome, Safari and Opera
    document.documentElement.webkitRequestFullscreen();
} else if (document.documentElement.msRequestFullscreen) { // IE/Edge
    document.documentElement.msRequestFullscreen();
}
};

  const exitFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) { // Firefox
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) { // Chrome, Safari and Opera
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) { // IE/Edge
      document.msExitFullscreen();
    }
  };

  const toggleFullScreen = () => {
    if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement) {
      enterFullScreen();
      setIsFullScreen(true);
    } else {
      exitFullScreen();
      setIsFullScreen(false);
    }
  };

  // ------

  const [isViewerOpen, setIsViewerOpen] = useState(false);
  // const [currentImage, setCurrentImage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);

  const openViewer = (image ,index) => {
    // setCurrentImage(image);
    setIsViewerOpen(true);
    setCurrentIndex(index)

  };

  const closeViewer = () => {
    setIsViewerOpen(false);
    setCurrentIndex(null)
    
    
    if (isFullScreen){
      exitFullScreen();
      setIsFullScreen(false);

    }

    
    
  };

  const showPrevious = useCallback((e) => {
    e.stopPropagation();
    setCurrentIndex((currentIndex - 1 + images.length) % images.length);
  }, [currentIndex, images.length]);


  const showNext = useCallback((e) => {
    e.stopPropagation();
    setCurrentIndex((currentIndex + 1) % images.length);
  }, [currentIndex, images.length]);

  

  useEffect(() => {
    const handleKeyUp = (event) => {
      if (event.keyCode === 37) {
        showPrevious(event);
      } else if (event.keyCode === 39) {
        showNext(event);
      }
    };

    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [showPrevious, showNext]);


return (
  <>
 
   <div className="image-grid">
        {images.map((src, index) => (
          <div key={index} className="image-container" onClick={() => openViewer(src.imageUrl,index)}>
            <img src={src.imageUrl} alt={`img-${index}`} className="grid-image" onLoad={()=>{setimageLoaded(true)}}/>
            {/* {!imageLoaded ? <MiniLoading/> : null} */}

          </div>
        ))}
      </div>
      
      {isViewerOpen && (
        <div className="slideshow">
          {/* full screen  */}
          {!is_IOS_MAC && (
        <div className='full-toggle' onClick={toggleFullScreen} >
          {isFullScreen ? <BsFullscreenExit />:<GoScreenFull /> }
        </div>
          )} 
        <div className="gallery-cross"  onClick={closeViewer}>
            <RxCross1 />
        </div>
        <div className="slideshow-container">
        <div id="previousBtn" className="previousBtn btn" onClick={showPrevious}>

     
        <MdOutlineArrowBackIos />
        </div>

            <img  id="image" src={images[currentIndex].imageUrl}/>

   


            <div id="nextBtn" className="nextBtn btn"  onClick={showNext}>
       
        <MdOutlineArrowForwardIos />

        </div>
    
          
        </div>
        </div>
        )}
  </>
  );
};

export default ImageGrid
