import React, { useState } from 'react'
// import {FadeLoader ,ClimbingBoxLoader,ScaleLoader} from "react-spinners";
import Lgif from "../Images/loading/load.gif"
// function Loading() {
//     let [loading, setLoading] = useState(true);
//     let [color, setColor] = useState("#D2BD7F");
  
//     return (
//       <div className="loading-container">
//       <div className="sweet-loading">
//         {/* <button onClick={() => setLoading(!loading)}>Toggle Loader</button> */}
//         <ClimbingBoxLoader 
//           color={color}
//           loading={loading}
//           style={{
//             position: "fixed",
//             top: "48%",
//             left: "50%",
//             transform: "translate(-50%, -50%)",
//           }}
//           size={10}
//           aria-label="Loading Spinner"
//           data-testid="loader"/>
       
//       </div>
  
//       </div>
//     );
//   }
  
  
  // export default Loading;

  
  function Loading() {
    return (
      <div>
        <div className="loading-container ">

      <img src={Lgif} alt="Loading..."  role='loading' />
        </div>
      </div>
    )
  }

  export function MiniLoading() {
    return (
      <div>
        <div className="loading-wrapper">

      <img src={Lgif} alt="Loading..."  role='loading' />
        </div>
      </div>
    )
  }
  
  export default Loading
  