import "../style/contactpage.css"
import axios from "../config/axios";
import React, { useEffect, useState } from 'react'
import Footer from '../components/Pagefooter'
import Navbar from "../components/sidepagesnavbar"
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {  Alert} from '@mui/material';


const GoogleMapEmbed = () => {
  return (
    <div className="map-container" >
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d878.205240131775!2d75.28552049999999!3d28.3037472!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39136b005e54ea63%3A0xcaefe7ef1735adff!2sHotel%20Rathore%20Heritage%20Haveli!5e0!3m2!1sen!2sin!4v1720598376381!5m2!1sen!2sin"
        width="600"
        height="450"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="eager"
        referrerPolicy="no-referrer-when-downgrade"
        title="Google Map"
      ></iframe>
    </div>
  );
};



function Contactpage({contactDetails}) {
  const [title, setTitle] = useState("Contact us");
  const [Loading , setLoading] = useState(false);
  const[successMsg , setsuccessMsg] = useState('')
  const[errorMsg , setErrorMsg] = useState('')
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    description: '',
    viewed: false,
    contactedOn: '',
  });

  useEffect(() => {
    if(successMsg) {
    setTimeout(() => {
        setsuccessMsg('')
      }, 7000);
    }
    if(errorMsg) {
    setTimeout(() => {
        setErrorMsg('')
      }, 7000);
    }
  },[errorMsg,successMsg])

  const sendContactData = async () => { 
    setsuccessMsg('')
    setErrorMsg('')
    try {
      setLoading(true); // Start loading
      const response = await axios.post('/contactus',formData); 
      setsuccessMsg(response.data.message)
      setLoading(false); // Stop loading

    } catch (error) {
      console.error("sending error", error.response.data.message);
      setErrorMsg(error.response.data.message)
      setLoading(false); // Stop loading
    }
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendContactData();
    
    setFormData({
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      description: '',
      viewed: false,
      contactedOn: '',
    })
  };



return (
    <HelmetProvider>
    <>
    <Helmet>
    <title>{title ? title : "Heritage Haveli Alsisar"}</title>

</Helmet>

<div className="super-container App-controller">
    <Navbar/> 
  <div className="contact-container">
    <div className="wrapper">
      <div className="text-box-heading contact-heading">
        <span> Contact</span>
      </div>
      <div className="location-humor">
        <svg
          width={44}
          height={44}
          viewBox="0 0 77 77"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M38.4989 0C23.7651 0 11.7786 11.9883 11.7786 26.7224C11.7786 40.9053 36.0223 74.0415 37.0546 75.4447L38.018 76.7558C38.1307 76.9099 38.3101 77 38.4989 77C38.6905 77 38.8688 76.9099 38.9826 76.7558L39.9454 75.4447C40.9783 74.0415 65.2215 40.9053 65.2215 26.7224C65.2215 11.9883 53.233 0 38.4989 0ZM38.4989 17.1505C43.778 17.1505 48.0708 21.4435 48.0708 26.7224C48.0708 31.9987 43.7778 36.2944 38.4989 36.2944C33.2228 36.2944 28.9269 31.9987 28.9269 26.7224C28.9269 21.4435 33.2226 17.1505 38.4989 17.1505Z"
            fill="#D2BD7F"
          />
        </svg>
      </div>
      <div className="contact-item address">
        <div className="detail text-box-content">
          <ul>
            <li>
            {contactDetails[0].address}
            </li>
          </ul>
        </div>
      </div>
      <div className="contact-item phone">
        <div className="detail text-box-content">
          <ul>
            <li>
              phone : {contactDetails[0].contact}
            </li>
          </ul>
        </div>
      </div>
      <div className="contact-item mail">
        <div className="detail text-box-content">
          <ul>
            <li>
              email : {contactDetails[0].email}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

{/* showing  message after form submit  */}
          {successMsg && (
              <Alert severity="success" sx={{ mb: 2 }}>
                {successMsg}
              </Alert>
            )}          
            {errorMsg && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {errorMsg}
              </Alert>
            )}
<div className="contact-form-container">
      <div className="form-wrapper">
        <form onSubmit={handleSubmit}>
          <input
            required
            type="text"
            id="fname"
            name="firstname"
            placeholder="Your name.."
            autoComplete="off"
            value={formData.firstname}
            onChange={handleChange}
          />
          <input
            required
            type="text"
            id="lname"
            name="lastname"
            placeholder="Your last name.."
            autoComplete="off"
            value={formData.lastname}
            onChange={handleChange}
          />
          <input
            required
            type="email"
            name="email"
            placeholder="Your email.."
            autoComplete="off"
            value={formData.email}
            onChange={handleChange}
          />
          <input
            required
            type="tel"
            name="phone"
            id="tel"
            placeholder="ex: +91 9898929296"
            autoComplete="off"
            value={formData.phone}
            onChange={handleChange}
          />
          <textarea
            required
            id="description"
            name="description"
            autoComplete="off"
            placeholder="Write here.."
            style={{ height: 200 }}
            value={formData.description}
            onChange={handleChange}
          />
          <input type="submit" value={Loading ?"Sending..." :"Send"} />
        </form>
      </div>
    </div>
    <br />
    <br />
    <br />

  <div className="map-location">
            <GoogleMapEmbed/>
  </div>

  <Footer contactDetails={contactDetails}/>
 
</div>

        
      
</>
    </HelmetProvider>
  )
}

export default Contactpage

