import React, { useState } from 'react';
import '../style/dashboard_booking.css';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from "../../config/axios";

function ToTitleCase({ str }) {
  return str.replace('_', ' ').replace(/(?:^|\s)\w/g, function (match) {
    return match.toUpperCase();
  });
}

function DashboardBooking({ allBookings,fechData }) {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [currentBooking, setCurrentBooking] = useState(null);

  const handleConfirm = (booking) => {
    setCurrentBooking(booking);
    setShowConfirmModal(true);
  };

  const handleCancel = (booking) => {
    setCurrentBooking(booking);
    setShowCancelModal(true);
  };

  const handleClose = () => {
    setShowConfirmModal(false);
    setShowCancelModal(false);
    setCurrentBooking(null);
  };

  const handleConfirmBooking = async() => {
    // Logic to confirm booking
    // console.log('Booking confirmed:', currentBooking);

    try {
      const response = await axios.put(`/bookings/${currentBooking.bookingId}/status` , {
        status: "confirmed",
      });
    } catch (error) {
      console.log(error);

    }
    handleClose();
    fechData() //fetch data again
  };

  const handleCancelBooking = async() => {
    // Logic to cancel booking
    try { 
      const response = await axios.put(`/bookings/${currentBooking.bookingId}/status` , {
        status: "cancelled",
      });
    } catch (error) {
      console.log(error);

    }
    // You can add code here to remove the booking from the list or update its status
    handleClose();
    fechData() //fetch data again
  };

  const pendingBookings = allBookings.filter(booking => booking.status === 'pending');

  return (
    <>
      <div className="dashboard-pending-booking-container">
        {pendingBookings.map((booking) => (
          <div className="dashboard-pending-booking-card" key={booking.bookingId}>
            <span className='flex-center fullwidth'><h3><ToTitleCase str={booking.firstname} /> <ToTitleCase str={booking.lastname} /></h3></span>
            <p><strong>Booking ID:</strong> {booking.bookingId}</p>
            <p><strong>Check-In:</strong> {booking.checkIn}</p>
            <p><strong>Check-Out:</strong> {booking.checkOut}</p>
            <p><strong>Address:</strong> {booking.address}</p>
            <p><strong>Status:</strong><span style={{ color: 'orange', marginLeft: '.51rem' }}>{booking.status}</span> </p>
            <p><strong>Total Nights:</strong> {booking.totalNight}</p>
            <p><strong>Room Type:</strong> {booking.roomType}</p>
            <p><strong>Total Room:</strong> {booking.totalRooms}</p>
            <p><strong>Rates Per Night:</strong> {booking.ratesPerNight}/-</p>
            <p><strong>Total Amount:</strong> {booking.totalPayableAmount}</p>
            {/* action buttons */}
            <Stack spacing={2} direction="row">
              <Button className='no-outline' color='error' variant="outlined" onClick={() => handleCancel(booking)}>Cancel booking</Button>
              <Button className='no-outline' variant="outlined" onClick={() => handleConfirm(booking)}>Confirm booking</Button>
            </Stack>
          </div>
        ))}

        <div className="dashboard-pending-if-not-pending-bookings">
          {pendingBookings.length === 0 ?
            <p>oh yeah! no pending bookings</p>:null
          }
        </div>
      </div>

      <Dialog
        open={showConfirmModal}
        onClose={handleClose}
      >
        <DialogTitle>Confirm Booking</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to confirm this booking?
          </DialogContentText>
          {currentBooking && (
            <div>
              <p><strong>Booking ID:</strong> {currentBooking.bookingId}</p>
              <p><strong>Name:</strong> {currentBooking.firstname} {currentBooking.lastname}</p>
              <p><strong>Check-In:</strong> {currentBooking.checkIn}</p>
              <p><strong>Check-Out:</strong> {currentBooking.checkOut}</p>
              <p><strong>Address:</strong> {currentBooking.address}</p>
              <p><strong>Total Nights:</strong> {currentBooking.totalNight}</p>
              <p><strong>Room Type:</strong> {currentBooking.roomType}</p>
              <p><strong>Total Room:</strong> {currentBooking.totalRooms}</p>
              <p><strong>Total Amount:</strong> {currentBooking.totalPayableAmount}</p>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">Cancel</Button>
          <Button onClick={handleConfirmBooking} color="primary">Confirm</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showCancelModal}
        onClose={handleClose}
      >
        <DialogTitle>Cancel Booking</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to cancel this booking?
          </DialogContentText>
          {currentBooking && (
            <div>
              <p><strong>Booking ID:</strong> {currentBooking.bookingId}</p>
              <p><strong>Name:</strong> {currentBooking.firstname} {currentBooking.lastname}</p>
              <p><strong>Check-In:</strong> {currentBooking.checkIn}</p>
              <p><strong>Check-Out:</strong> {currentBooking.checkOut}</p>
              <p><strong>Address:</strong> {currentBooking.address}</p>
              <p><strong>Total Nights:</strong> {currentBooking.totalNight}</p>
              <p><strong>Room Type:</strong> {currentBooking.roomType}</p>
              <p><strong>Total Room:</strong> {currentBooking.totalRooms}</p>
              <p><strong>Total Amount:</strong> {currentBooking.totalPayableAmount}</p>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">Cancel</Button>
          <Button onClick={handleCancelBooking} color="primary">Confirm</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DashboardBooking;
