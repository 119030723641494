// import React, { useEffect, useState } from 'react';
// import { useAuth } from '../config/AuthContext';
// import {useNavigate } from "react-router-dom";


// const Login = () => {
//   const [admin, setAdmin] = useState('');
//   const [key, setKey] = useState('');
//   const { login ,logout} = useAuth();
//   let navigate = useNavigate(); //to redirect 

//   useEffect(() => {
//     const token = localStorage.getItem('authToken');
//     if (token){
//        navigate("/login/dashboard");
//     }
//   })


//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     try {
//       await login(admin, key);
//       // Redirect to dashboard or home
//     } catch (error) {
//       console.error('Login failed', error);
//     }
//   };

//   return (
//     <>
  
//     <form onSubmit={handleSubmit}>
//       <input
//         type="text"
//         value={admin}
//         onChange={(e) => setAdmin(e.target.value)}
//         placeholder="Admin"
//       />
//       <input
//         type="password"
//         value={key}
//         onChange={(e) => setKey(e.target.value)}
//         placeholder="Key"
//         autoComplete='off'
//       />
//       <button type="submit">Login</button>
//     </form>
// <br />
// <br />
// <br />
//     <button style={{marginTop:'12rem'}} onClick={()=>{logout()}}>logout</button>
//     </>

    
//   );
// };

// export default Login;

import React, { useEffect, useState } from 'react';
import { useAuth } from '../config/AuthContext';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Box,
  Typography,
  Container,
  Alert,
  CircularProgress,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme();

const Login = () => {
  const [admin, setAdmin] = useState('');
  const [key, setKey] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [retryAfter, setRetryAfter] = useState(null);
  const [countdown, setCountdown] = useState(null);
  const { login } = useAuth();
  let navigate = useNavigate(); // to redirect

  // if already logged in then navigate to dashboard
  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      navigate('/login/dashboard');
    }
  }, [navigate]);

  // set countdown and desable if loginLimite exceeded 
  useEffect(() => {
    if (retryAfter !== null) {
      setCountdown(retryAfter);
      const interval = setInterval(() => {
        setCountdown(prevCountdown => {
          if (prevCountdown <= 1) {
            clearInterval(interval);
            setRetryAfter(null);
            return null;
          }
          return prevCountdown - 1;
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [retryAfter]);

  // handle login submit and errors 
  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');
    setLoading(true);
    setRetryAfter(null);

    try {
      await login(admin, key);
      navigate('/rhh-admin/dashboard');
    } catch (error) {
      if (error.response && error.response.status === 429 && error.response.data.retryAfter) {
        setRetryAfter(error.response.data.retryAfter);
        setError(`Too many login attempts. Please try again in ${error.response.data.retryAfter} seconds.`);
      } else {
        setError('Login failed. Please check your credentials and try again.');
      }
      // console.error('Login failed', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
        <Avatar sx={{ m: 1, width: 72, height: 72 }}>
            <img
              className='center-image'
              src="https://ik.imagekit.io/transferRRHphotos/heritage/IMG-20150204-WA0080.jpg?updatedAt=1719216614160"
              alt=""
              style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '50%' }}
            />
          </Avatar>
          {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar> */}
          <Typography component="h1" variant="h5">
            Login
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            {error && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error}
              </Alert>
            )}
            {countdown !== null && (
              <Alert severity="warning" sx={{ mb: 2 }}>
                Too many login attempts. Please try again in {countdown} seconds.
              </Alert>
            )}
            <TextField
              margin="normal"
              required
              fullWidth
              id="admin"
              label="Admin"
              name="admin"
              autoComplete="off"
              autoFocus
              value={admin}
              onChange={(e) => setAdmin(e.target.value)}
              disabled={countdown !== null}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#D2BD7F',
                  },
                  '&:hover fieldset': {
                    borderColor: '#C0A76E',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#C0A76E',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: '#D2BD7F',
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#C0A76E',
                },
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="key"
              label="Key"
              type="password"
              id="key"
              autoComplete="off"
              value={key}
              onChange={(e) => setKey(e.target.value)}
              disabled={countdown !== null}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#D2BD7F',
                  },
                  '&:hover fieldset': {
                    borderColor: '#C0A76E',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#C0A76E',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: '#D2BD7F',
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#C0A76E',
                },
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, backgroundColor: "#D2BD7F", '&:hover': { backgroundColor: "#C0A76E" } }}
              disabled={loading || countdown !== null}
            >
              {loading ? <CircularProgress size={24} sx={{color:"#D2BD7F"}} /> : 'Login'}
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default Login;
