import React, { useEffect, useState } from "react";
import "../style/admin_bookings.css";
import axios from "../../config/axios";
import { FiRefreshCcw } from "react-icons/fi";
import { CiEdit } from "react-icons/ci";
import Modal from "react-modal";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import ReactPaginate from "react-paginate";

// Custom styles for the modal
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1000, // Increase the z-index value
  },
};

function BookingTable() {
  // stats
  const [textFilter, setTextFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [bookingData, setBookingData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [bookingsPerPage] = useState(5); //number of bookings to show per page
  // popup model
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [actionName, setactionName] = useState();

  const statusColors = {
    pending: "orange",
    cancelled: "red",
    confirmed: "green",
  };

  const fetchBookingData = async () => {
    try {
      setLoading(true);
      const response = await axios.get("/bookings");
      // filter by date ,so that recent bookings show on top
      const parseDate = (dateStr) => {
        const [datePart, timePart] = dateStr.split(' , ');
        const [day, month, year] = datePart.split('/');
        let [time, period] = timePart.split(' ');
        let [hours, minutes, seconds] = time.split(':');
        
        hours = period === 'PM' ? (hours === '12' ? 12 : parseInt(hours, 10) + 12) : (hours === '12' ? 0 : parseInt(hours, 10));
        
        return new Date(year, month - 1, day, hours, minutes, seconds);
      };
  
      const sortedData = response.data.sort((a, b) => parseDate(b.bookedOn) - parseDate(a.bookedOn));
      setBookingData(sortedData);
      /////end/////
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBookingData();
  }, []);

  const handleTextFilterChange = (e) => {
    setTextFilter(e.target.value);
  };

  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
  };

  const handleBookingEdit = (data) => {
    // console.log(data);

    setSelectedBooking(data);
    setModalIsOpen(true);
  };

  // for pop modal
  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedBooking(null);
  };

  const sendToServer = async () => {
    try {
      setLoading(true);
      const response = await axios.put(
        `/bookings/${selectedBooking.bookingId}/status`,
        {
          status: actionName,
        }
      );
      fetchBookingData();
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }

    // console.log(selectedBooking.bookingId,"set status to : ",actionName);
    closeModal();
  };
  const handleBookingStatus = (e) => {
    let value = e.target.value;
    if (value === null || value === undefined || value === "") {
      return;
    }
    setactionName(value); //for change booking status via modal
  };

  // Optional chaining operator (?.): Prevents errors when accessing properties or calling methods on null or undefined.
  const filteredData = bookingData?.filter((booking) => {
    const textMatch =
      booking.firstname.toLowerCase().includes(textFilter.toLowerCase()) ||
      booking.lastname.toLowerCase().includes(textFilter.toLowerCase()) ||
      booking.bookingId.toLowerCase().includes(textFilter.toLowerCase()) ||
      booking.status.toLowerCase().includes(textFilter.toLowerCase());

    const statusMatch = statusFilter ? booking.status === statusFilter : true;

    return textMatch && statusMatch;
  });

  const pageCount = Math.ceil((filteredData?.length || 0) / bookingsPerPage);
  const offset = currentPage * bookingsPerPage;
  const currentBookings = filteredData?.slice(offset, offset + bookingsPerPage);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading booking data</p>;

  return (
    <>
      <div
        className="flex-center refresh-me "
        onClick={() => {
          fetchBookingData();
        }}
      >
        <FiRefreshCcw />
      </div>

           {/* for pagination  */}
           <div className="pagination-container">
          <ReactPaginate
            previousLabel={"previous"}
            nextLabel={"next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            activeClassName={"active"}
          />
        </div>

        


      <div className="admin-small-nav-flex admin-small-nav mb-3">
        <input
          type="text"
          placeholder="Filter by Name or Booking ID or status"
          value={textFilter}
          onChange={handleTextFilterChange}
          className="filter-input"
        />

        <div className="admin-select">
          <select
            value={statusFilter}
            onChange={handleStatusFilterChange}
            className="filter-select"
          >
            <option value="">All Types</option>
            <option value="pending">Pending</option>
            <option value="confirmed">Confirmed</option>
            <option value="cancelled">Cancelled</option>
          </select>
        </div>

     
      </div>

      <div className="booking-table-container">
        <table className="booking-table">
          <thead>
            <tr>
              <th>Action</th>
              <th>Status</th>
              <th>Booking ID</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Zip Code</th>
              <th>Address</th>
              <th>Total Person</th>
              <th>Total Room</th>
              <th>Check In</th>
              <th>Check Out</th>
              <th>Pay Online</th>
              <th>Room Type</th>
              <th>Total Days</th>
              <th>Total Nights</th>
              <th>Booked on</th>
              <th>Rates per Night</th>
              <th>Total Amount</th>
            </tr>
          </thead>
          <tbody>
            {currentBookings?.map((booking) => (
              <tr key={booking.bookingId}>
                <td
                  className="pointer"
                  onClick={() => handleBookingEdit(booking)}
                >
                  <CiEdit />
                </td>
                <td>
                  <span style={{ color: statusColors[booking.status] }}>
                    {booking.status}
                  </span>
                </td>
                <td>{booking.bookingId}</td>
                <td>{booking.firstname}</td>
                <td>{booking.lastname}</td>
                <td style={{ whiteSpace: "nowrap" }}>
                  {booking.contact.phone}
                </td>
                <td>{booking.contact.email}</td>
                <td>{booking.zipCode}</td>
                <td>{booking.address}</td>
                <td>{booking.totalPersons}</td>
                <td style={{fontWeight: "bold" }}>{booking.totalRooms}</td>
                <td style={{ whiteSpace: "nowrap", fontWeight: "bold" }}>
                  {booking.checkIn}
                </td>
                <td style={{ whiteSpace: "nowrap", fontWeight: "bold" }}>
                  {booking.checkOut}
                </td>
                {/* heading is Pay Online  */}
                <td>{booking.payOnline ? "Yes" : "No"}</td>
                <td>{booking.roomType}</td>
                <td>{booking.totalDay}</td>
                <td>
                  <b>{booking.totalNight}</b>
                </td>
                <td>{booking.bookedOn}</td>
                <td>{booking.ratesPerNight}/-</td>
                <td>
                  {" "}
                  <b>{booking.totalPayableAmount}/-</b>{" "}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Confirm Booking"
      >
        {selectedBooking && (
          <div className="booking-modal-react">
            <h2 className="flex-center mb-3">Detailed View</h2>
            <p>
              <strong>Booking ID:</strong> {selectedBooking.bookingId}
            </p>
            <p>
              <strong>Name:</strong> {selectedBooking.firstname}{" "}
              {selectedBooking.lastname}
            </p>
            <p>
              <strong>From:</strong> {selectedBooking.address}
            </p>
            <p>
              <strong>Phone:</strong> {selectedBooking.contact.phone}
            </p>
            <p>
              <strong>Email:</strong> {selectedBooking.contact.email}
            </p>
            <p>
              <strong>Check In:</strong> {selectedBooking.checkIn}
            </p>
            <p>
              <strong>Check Out:</strong> {selectedBooking.checkOut}
            </p>
            <p>
              <strong>Status:</strong> {selectedBooking.status}
            </p>

            <div className="admin-select mb-3">
              <select
                value={actionName}
                onChange={handleBookingStatus}
                className="filter-select"
              >
                <option value="">Action</option>
                <option value="pending">Pending</option>
                <option value="confirmed">Confirmed</option>
                <option value="cancelled">Cancelled</option>
              </select>
            </div>

            <Stack spacing={2} direction="row">
              <Button variant="outlined" onClick={sendToServer}>
                Confirm
              </Button>
              <Button color="error" variant="outlined" onClick={closeModal}>
                Cancel
              </Button>
            </Stack>
          </div>
        )}
      </Modal>
    </>
  );
}

export default BookingTable;
