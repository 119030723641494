import React from 'react';
import '../style/confirmation_modal_popup.css'; // Import the CSS for styling

const ConfirmationModal = ({ show, onClose, onConfirm, title, message }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="confirmation-modal-popup-overlay">
      <div className="confirmation-modal-popup">
        <div className="confirmation-modal-popup-header">
          <h2>{title}</h2>
        </div>
        <div className="confirmation-modal-popup-body">
          <p>{message}</p>
        </div>
        <div className="confirmation-modal-popup-footer">
          <button onClick={onClose} className="confirmation-modal-popup-btn confirmation-modal-popup-cancel-btn">No</button>
          <button onClick={onConfirm} className="confirmation-modal-popup-btn confirmation-modal-popup-confirm-btn">Yes</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
