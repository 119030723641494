import React from 'react'
import img from "../Images/others/bed1.png"

function Humour({homepageData}) {
  const Six = homepageData.find(item => item.index === 6);
  return (
    <div>
      <div className="flex-center booking-page-humour">
        <div className="booking-page-humour-wrapper">

        <div className="booking-page-humour-image">

        <img src={img} alt="" draggable="false"/>
        </div>

        <div className="flex-center booking-page-humour-content">
          <div className="booking-page-humour-content-wrapper">

          {/* <span className='text-box-heading'>Looking for a Room?</span> */}
          <span className='text-box-content'>
          {Six && <span>{Six.description}</span>}
            </span>

          </div>
        </div>
        {/* <div className="neck-line-mini mt-3">
            <svg
            width={70}
            height={4}
            viewBox="0 0 119 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            >
            <path
                d="M0 2C0 0.895431 0.895431 0 2 0H117C118.105 0 119 0.895431 119 2C119 3.10457 118.105 4 117 4H2C0.89543 4 0 3.10457 0 2Z"
                fill="#B3946A"
            />
            </svg>
        </div> */}
        </div>
      </div>
    </div>
  )
}

export default Humour
