// import axios from "axios";

// // we need to pass the baseURL as an object
// const API = axios.create({
//   // baseURL: "http://localhost:5000/",
// //   baseURL: "http://192.168.0.103:5000/",
//   baseURL: "http://192.168.43.158:4242/",
//   withCredentials: false,
//   headers: {
//     Accept: "application/json",
//     "Content-Type": "application/json",
//   },

// });

// export default API;

import axios from 'axios';

// Create an instance of axios
const API = axios.create({
  baseURL: "https://api-lemon-phi-47.vercel.app/api/",
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

// Add a request interceptor to include the token in headers
API.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('authToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default API;
