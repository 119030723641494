import { createSlice ,createAsyncThunk} from '@reduxjs/toolkit'
import dayjs from 'dayjs';
import axios from '../../config/axios';


// Async thunk to add custom blocked date 
export const customDateBlock = createAsyncThunk(
  'datePicker/customDateBlock',
  async ({date }) => {
    const response = await axios.post('/custom-block-date', {date});
    return response.data;
  }
);


// Async thunk to fetch blocked dates
export const fetchBlockedDatesAsync = createAsyncThunk(
  'datePicker/fetchBlockedDatesAsync',
  async () => {
      const response = await axios.get('/blocked-dates');
      return response.data;
  }
);

// Async thunk to add blocked date range
export const addBlockedDateRangeAsync = createAsyncThunk(
    'datePicker/addBlockedDateRangeAsync',
    async ({ start, end }) => {
      const response = await axios.post('/block-dates', { start, end });
      return response.data;
    }
  );
  
  // Async thunk to remove blocked date range
  export const removeBlockedDateRangeAsync = createAsyncThunk(
    'datePicker/removeBlockedDateRangeAsync',
    async ({ start, end }) => {
      const response = await axios.post('/unblock-dates', { start, end });
      return response.data;
    }
  );
  

export const datePickerSlice = createSlice({
name: 'datePickerSlice',
initialState: {
value: [null, null],
diff:0, //date diff check in check out 
totalNight:0, //total night
blockedDates: [], // Initially empty array of blocked dates
error:false
},
reducers: {
  // checkin 
from: (state,action) => {
    state.value[0] =  action.payload
    state.error=false
},
// checkout 
to: (state,action) => {
    state.value[1] =  action.payload
    state.error=false
},
// check correct date formate from checkin to checkout and give day difference between them 
daydiff: (state) => {
    try {
        state.error=false
        if (state.value[0] != null){
            if (state.value[1] != null){

            const to = dayjs(state.value[0],"DD-MM-YYYY")
            const from = dayjs(state.value[1],"DD-MM-YYYY")
            let daydiff =from.diff(to,'day')

            if (daydiff===0){
              //same day
              state.error=false
              state.diff = 1
              state.totalNight = 1 //for payment purpose // not to get 0 payment

            }
            else{

              if (daydiff > 0){
                state.error=false
                const daydifference=daydiff+1
                  state.diff = daydifference
                  state.totalNight = daydiff
                 
              }
              else{
                state.error = true
      
              }

            }


        }
        }

        else{
            state.diff = 0;
        }

        
        
    } catch (error) {
        console.log(error) 
        
    }
   
},

},
extraReducers: (builder) => {
    builder
      .addCase(customDateBlock.fulfilled, (state, action) => {
        state.blockedDates = action.payload;
      })    
      .addCase(addBlockedDateRangeAsync.fulfilled, (state, action) => {
        state.blockedDates = action.payload;
      })
      .addCase(removeBlockedDateRangeAsync.fulfilled, (state, action) => {
        state.blockedDates = action.payload;
      })
      .addCase(fetchBlockedDatesAsync.fulfilled, (state, action) => {
        state.blockedDates = action.payload;
    });
  },
})

// Action creators are generated for each case reducer function
export const { from, to ,daydiff } = datePickerSlice.actions

export default datePickerSlice.reducer



// addBlockedDateRange: (state, action) => {
//     const { start, end } = action.payload;
//     let current = dayjs(start, "DD-MM-YYYY");
//     const endDate = dayjs(end, "DD-MM-YYYY");

//     console.log(`Blocking dates from ${start} to ${end}`);
    
//     const newBlockedDates = [...state.blockedDates]; // Create a copy of the current blocked dates

//     while (current.isBefore(endDate) || current.isSame(endDate)) {
//       const formattedDate = current.format("DD-MM-YYYY");
//       if (!newBlockedDates.includes(formattedDate)) {
//         newBlockedDates.push(formattedDate);
//         console.log(`Blocked date: ${formattedDate}`);
//       }
//       current = current.add(1, 'day');
//     }

//     state.blockedDates = newBlockedDates; // Assign the new array to the state
//     console.log(state.blockedDates); // This should now show the updated array
//   },
//   removeBlockedDateRange: (state, action) => {
//     const { start, end } = action.payload;
//     let current = dayjs(start, "DD-MM-YYYY");
//     const endDate = dayjs(end, "DD-MM-YYYY");

//     console.log(`Unblocking dates from ${start} to ${end}`);
    
//     const newBlockedDates = state.blockedDates.filter(date => {
//       let dateToCheck = dayjs(date, "DD-MM-YYYY");
//       return dateToCheck.isBefore(current) || dateToCheck.isAfter(endDate);
//     });

//     state.blockedDates = newBlockedDates;
//     console.log(state.blockedDates); // This should now show the updated array
//   },