
import React, { useEffect, useState } from 'react';
import axios from "../../config/axios";
import { FiRefreshCcw } from "react-icons/fi";
import { MdDoneAll } from "react-icons/md";
import { MdDone } from "react-icons/md";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Typography,
  Box,
  TablePagination,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

const ContactUsTable = () => {
  const [contactData, setContactData] = useState([]);
  const [error, setError] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [contactToDelete, setContactToDelete] = useState(null);

  const fetchData = async () => {
    try {
      const response = await axios.get('/contactus'); // Adjust the endpoint as necessary
      setContactData(response.data);
    } catch (err) {
      setError('Error fetching data');
    } finally {

    }
  };
  useEffect(() => {

    fetchData();
  }, []);

  const handleClickOpen = async (contact) => {
    try {
      await axios.post(`/contactus/view/${contact._id}`);
      setSelectedContact(contact);
      setOpen(true);
      // Update the view status in the state
      setContactData((prevData) =>
        prevData.map((item) =>
          item._id === contact._id ? { ...item, viewed: true } : item
        )
      );
    } catch (error) {
      console.error('Error updating view status:', error);
    }
  };

  const handleDeleteOpen = (contact) => {
    setContactToDelete(contact);
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setContactToDelete(null);
  };

  const handleDeleteConfirm = async () => {
    try {
      await axios.delete(`/contactus/${contactToDelete._id}`);
      setContactData((prevData) => prevData.filter((item) => item._id !== contactToDelete._id));
    } catch (error) {
      console.error('Error deleting contact:', error);
    } finally {
      handleDeleteClose();
    }
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedContact(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const truncateDescription = (description) => {
    const words = description.split(' ');
    if (words.length <= 3) return description;
    return `${words.slice(0, 3).join(' ')}...`;
  };


  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <Typography variant="h6" color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <> 
      <div className="flex-center refresh-me " onClick={()=>{fetchData()}}>
      <FiRefreshCcw />
      </div>
    <Container>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{fontWeight:'bold'}}>Viewed</TableCell>
              <TableCell sx={{fontWeight:'bold'}}>First Name</TableCell>
              <TableCell sx={{fontWeight:'bold'}}>Last Name</TableCell>
              <TableCell sx={{fontWeight:'bold'}}>Email</TableCell>
              <TableCell sx={{fontWeight:'bold'}}>Phone</TableCell>
              <TableCell sx={{fontWeight:'bold'}}>Description</TableCell>
              <TableCell sx={{fontWeight:'bold'}}>Contacted On</TableCell>
              <TableCell sx={{fontWeight:'bold'}}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contactData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((contact) => (
              <TableRow key={contact._id} style={{ backgroundColor: contact.viewed ? '#f0f0f0' : 'white' }}>
                <TableCell>{contact.viewed ?<span style={{color:"green",fontSize:"1.52rem"}}><MdDoneAll /> </span> : <span style={{color:"grey",fontSize:"1.52rem"}}><MdDone /> </span>}</TableCell>
                <TableCell>{contact.firstname}</TableCell>
                <TableCell>{contact.lastname}</TableCell>
                <TableCell>{contact.email}</TableCell>
                <TableCell>{contact.phone}</TableCell>
                <TableCell>{truncateDescription(contact.description)}</TableCell>
                <TableCell>{new Date(contact.contactedOn).toLocaleString()}</TableCell>
                <TableCell>
                  <Button sx={{marginRight:2 , marginBottom:1}} variant="outlined" color="primary" onClick={() => handleClickOpen(contact)}>
                    View
                  </Button>
                  <Button sx={{ marginBottom:1}} variant="outlined" color="secondary" onClick={() => handleDeleteOpen(contact)}>
                    Delete
                  </Button>
                    </TableCell>
                </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={contactData.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Contact Description</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {selectedContact && selectedContact.description.split('\n').map((line, index) => (
              <span key={index}>{line}<br /></span>
            ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={deleteOpen} onClose={handleDeleteClose}>
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this contact query ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
    </>
  );
};

export default ContactUsTable;
