import "../style/admin_homepage.css";
import React, { useState, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import axios from '../../config/axios';

export default function AdminHomeData() {
  const [editMode, setEditMode] = useState(false);
  const [homeData, setHomeData] = useState([]);
  const [editValues, setEditValues] = useState({});
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [currentEditId, setCurrentEditId] = useState(null);

  useEffect(() => {
    // Fetch data from the server
    const fetchData = async () => {
      try {
        const response = await axios.get('/homedata');
        setHomeData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleEditModeChange = () => {
    setEditMode(!editMode);
  };

  const handleInputChange = (id, field, value) => {
    setEditValues((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        [field]: value,
      },
    }));
  };

  const handleOpenConfirm = (id) => {
    const dateTime = new Date().toISOString();
    setEditValues((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        dateTime,
      },
    }));
    setCurrentEditId(id);
    setConfirmOpen(true);
  };

  const handleCloseConfirm = () => {
    setConfirmOpen(false);
    setCurrentEditId(null);
  };

  const handleConfirm = async () => {
    const updatedItem = { ...editValues[currentEditId] };

    try {
      await axios.put(`/homedata/${currentEditId}`, updatedItem);
      setHomeData((prev) =>
        prev.map((item) => (item._id === currentEditId ? { ...item, ...updatedItem } : item))
      );
      setEditValues((prev) => {
        const { [currentEditId]: _, ...rest } = prev;
        return rest;
      });
      handleCloseConfirm();
    } catch (error) {
      console.error('Error updating data:', error);
    }
  };

  return (
    <div>
      <div className="edit-mode-switch">
        <label>Edit Mode</label>
        <Switch
          checked={editMode}
          onChange={handleEditModeChange}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </div>
      {homeData.map((item) => (
        <Accordion key={item._id} >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel-${item._id}-content`}
            id={`panel-${item._id}-header`}
          >
            {item.title}
          </AccordionSummary>
          <AccordionDetails>
            <div className="home-input-container-in-admin">
              <div className="form-group">
                {editMode ? (
                  <input
                    type="text"
                    className="form-control custom-outer"
                    id={`title-${item._id}`}
                    defaultValue={item.title}
                    onChange={(e) =>
                      handleInputChange(item._id, 'title', e.target.value)
                    }
                  />
                ) : (
                  <p>index : {item.index}</p>
                )}
              </div>
              <div className="form-group">
                {editMode ? (
                  <input
                    type="text"
                    className="form-control custom-outer"
                    id={`imageUrl-${item._id}`}
                    defaultValue={item.imageUrl}
                    onChange={(e) =>
                      handleInputChange(item._id, 'imageUrl', e.target.value)
                    }
                  />
                ) : (
                  <div className="upper-home-container-for-image flex-center">
                    <div className="home-data-image-size">
                      {item.imageUrl==="none"?<p>none</p>:<img src={item.imageUrl} alt={`image-${item._id}`} />}
                      
                    </div>
                  </div>
                )}
              </div>
              <div className="form-group">
                {editMode ? (
                  <textarea
                    className="form-control custom-outer"
                    id={`description-${item._id}`}
                    rows={3}
                    defaultValue={item.description}
                    onChange={(e) =>
                      handleInputChange(item._id, 'description', e.target.value)
                    }
                  />
                ) : (
                  <p>{item.description}</p>
                )}
              </div>
              {!editMode && item.dateTime && (
                <div className="form-group">
                  <p>updated at: {new Date(item.dateTime).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' })}</p>
                </div>
              )}
            </div>
          </AccordionDetails>
          {editMode && (
            <AccordionActions>
              <Button onClick={handleEditModeChange}>Cancel</Button>
              <Button onClick={() => handleOpenConfirm(item._id)}>Confirm</Button>
            </AccordionActions>
          )}
        </Accordion>
      ))}
      <Modal
        open={confirmOpen}
        onClose={handleCloseConfirm}
        aria-labelledby="confirm-modal-title"
        aria-describedby="confirm-modal-description"
      >
        <Box className="modal-box">
          <h2 id="confirm-modal-title">Confirm Edit</h2>
          <p id="confirm-modal-description">Are you sure you want to save these changes?</p>
          <div className="modal-buttons">
            <Button onClick={handleCloseConfirm}>Cancel</Button>
            <Button onClick={handleConfirm}>Confirm</Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
