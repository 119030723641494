
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
// using cookies 
import Cookies from "universal-cookie";
const cookies = new Cookies();

const PrivateRoute = () => {
//recieve adminId and message in AdminInfo cookie
const admin =cookies.get("AdminInfo") //if admin loged with credentials then cookie wiil have the data
const token = localStorage.getItem('authToken');

if (token) {

  return admin  ? <Outlet /> : <Navigate to="/rhh-admin/login" />;
}

};

export default PrivateRoute;
