import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../config/axios'; 

// Async thunk to fetch availableRooms from the database
export const fetchAvailableRooms = createAsyncThunk(
    'available/fetchAvailableRooms',
    async (_, thunkAPI) => {
        try {
            const response = await axios.get('/dashboard'); 
            return response.data[0]; // Assuming availableRooms is returned directly
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

export const availableSlice = createSlice({
    name: 'available',
    initialState: {
        availableRooms: 4, // Initial state, adjust as per your requirement
        status: 'idle', // Possible values: 'idle', 'loading', 'succeeded', 'failed'
        error: null,
        allowedAdultsMultipleOf: 4,
        availableRoomsType: []
    },
    reducers: {
        //These 2 are not in use anywhere currently
        changeAvailableRooms: (state, action) => {
            state.availableRooms = action.payload;
        },
        changeAvailableRoomType: (state, action) => {
            state.availableRoomsType = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAvailableRooms.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAvailableRooms.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.availableRooms = action.payload.availableRooms;
                state.allowedAdultsMultipleOf = action.payload.allowedAdultsMultipleOf;
                let room_rates_object = action.payload.availableRoomsTypeWithRates
                state.availableRoomsType = room_rates_object
                // state.availableRoomsType = Object.keys(room_rates_object)
            })
            .addCase(fetchAvailableRooms.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    }
});

export const { changeAvailableRooms, changeAvailableRoomType } = availableSlice.actions;


export default availableSlice.reducer;
