import { configureStore } from '@reduxjs/toolkit'
import datePickerReducer from './reducers/dateslice'
import paySliceReducer from './reducers/payslice'
import availableSliceReducer from './reducers/availablityslice'

export default configureStore({
  reducer: {
    datePicker: datePickerReducer,
    paySlice: paySliceReducer,
    availableSlice: availableSliceReducer,
  
  },
})