import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from './axios';
import {useNavigate } from "react-router-dom";
// using cookies 
import Cookies from "universal-cookie";
const cookies = new Cookies();

//these things checks and login register and check token thorugh protected and secure my website   
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  let navigate = useNavigate(); //to redirect 

  // fetch admin in logged in /check auth token
const fetchAdmin =async()=>{
    try {
        const response = await axios.get('/protected')
          // set the cookie
          cookies.set("AdminInfo",response.data, {
          path: "/"
        });   
        
      } catch (error) {
        console.error('auth error!:', error.response ? error.response.data.message : error);
        logout();
          
        
      }
}
useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      fetchAdmin();
    }
  }, []);

// // login admin 
const login = async (admin, key) => {
  try {
    const response = await axios.post('/login', { admin, key });
    const { token } = response.data; // Ensure token is being correctly received
    localStorage.setItem('authToken', token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const userResponse = await axios.get('/protected');

    // Use cookie
    cookies.set("AdminInfo", userResponse.data, { path: "/" });

    return true;
  } catch (error) {
    console.error('Login error:', error.response ? error.response.data.message : error);
    if (error.response && error.response.status === 429) {
      throw error; // Propagate error to caller
    }
    throw new Error('Login failed. Please check your credentials and try again.');
  }
};

// create admin 
  const register = async (admin, key) => {
    try {
      const res=await axios.post('/register', { admin, key });
      return true;
    } catch (error) {
      // console.error('register error:', error.response ? error.response.data.message : error);
      throw error;  

    }
  };

  // logout admin 
  const logout = () => {
    localStorage.removeItem('authToken');
    delete axios.defaults.headers.common['Authorization'];
    cookies.remove("AdminInfo", { path: "/" });
  };

  return (
    <AuthContext.Provider value={{login, register, logout}}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

