import React from 'react'
import '../style/Pagefooter.css';

function Pagefooter({contactDetails}) {
  return (
    <>

    <footer>
    <div className="footer-wrap">
    <div className="footer-address">
    <span>
       {contactDetails[0].address}
    </span>
    </div>
    <div className="footer-contact">
    <span> Email : {contactDetails[0].email} </span>
    <br />
    <span> Mobile: {contactDetails[0].contact}</span>
    </div>
    </div>
    <div className="copyright">
    <span> {contactDetails[0].copyright}</span>
    </div>
    </footer>

    </>
)
}

export default Pagefooter
