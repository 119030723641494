import React, { useState } from "react";
import { Modal, Box, Typography, Button } from "@mui/material";


function ApologyModal({ open, handleClose }) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 350,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography id="modal-title" variant="h6" component="h2">
          Apology for Inconvenience
        </Typography>
        <Typography id="modal-description" sx={{ mt: 2 }}>
          We regret to inform you that the online payment option is currently
          unavailable due to an ongoing issue with our bank. We are working
          diligently to resolve this as soon as possible.
        </Typography>
        

        <Button onClick={handleClose}
          sx={{
            mt: 3,
            bgcolor: "green", 
            "&:hover": {
              bgcolor: "#388e3c", //  green color on hover
            },
          }}
          variant="contained"
          color="primary"
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
}

export default ApologyModal;
