// import React, { useState, useEffect } from 'react';
// import { DatePicker, Space } from 'antd';
// import { Button, Typography, Box, List, ListItem, ListItemText } from '@mui/material';
// import { useDispatch, useSelector } from 'react-redux';
// import { addBlockedDateRangeAsync, removeBlockedDateRangeAsync, fetchBlockedDatesAsync, customDateBlock } from '../../stateManager/reducers/dateslice';
// import dayjs from 'dayjs';
// import axios from '../../config/axios';

// const { RangePicker } = DatePicker;

// const AdminDateSelector = () => {
//   // State
//   const [selectedRange, setSelectedRange] = useState([]);
//   const [customDate, setCustomDate] = useState('');
  
//   // Redux
//   const blockedDates = useSelector((state) => state.datePicker.blockedDates);
//   const dispatch = useDispatch();

//   useEffect(() => {
//     dispatch(fetchBlockedDatesAsync());
//   }, [dispatch]);

//   const handleRangeChange = (dates) => {
//     setSelectedRange(dates);
//   };

//   const handleBlockDateRange = () => {
//     if (selectedRange && selectedRange.length === 2) {
//       const [start, end] = selectedRange;
//       dispatch(addBlockedDateRangeAsync({ start: start.format("DD-MM-YYYY"), end: end.format("DD-MM-YYYY") }));
//       setSelectedRange([]);
//     }
//   };

//   const handleUnblockDateRange = () => {
//     if (selectedRange && selectedRange.length === 2) {
//       const [start, end] = selectedRange;
//       dispatch(removeBlockedDateRangeAsync({ start: start.format("DD-MM-YYYY"), end: end.format("DD-MM-YYYY") }));
//       setSelectedRange([]);
//     }
//   };

//   const handleUnblockDate = async (date) => {
//     try {
//       const DeleteDate_response = await axios.delete(`/blocked-dates/${date}`);
//       console.log(DeleteDate_response.data);
//       console.log(DeleteDate_response.data.message);
//       dispatch(fetchBlockedDatesAsync()); // Refresh the blocked dates after deletion
//     } catch (error) {
//       console.error('Error unblocking date:', error);
//     }
//   };

//   const handleSubmit = (event) => {
//     event.preventDefault(); // Prevent default form submission

//     if (customDate.trim() !== "") {
//       dispatch(customDateBlock({ date: customDate.trim() }));
//       setCustomDate(''); // Clear input after dispatching
//     } else {
//       // Handle case where date is empty (optional)
//       console.log("Please enter a valid date.");
//     }
//   };

//   const handleInputChange = (event) => {
//     setCustomDate(event.target.value); // Update state with user input
//   };

//   const disableDates = (currentDate) => {
//     if (!currentDate) return false;
//     // Disable dates before today 
//     return currentDate.isBefore(dayjs().startOf('day'));
//   };

//   return (
//     <>
//       <form onSubmit={handleSubmit}>
//         <div className="form-group">
//           <input
//             type="text"
//             className="form-control"
//             id="customDate"
//             aria-describedby="customDateHelp"
//             placeholder="Enter custom date"
//             value={customDate}
//             onChange={handleInputChange}
//           />
//         </div>
//         <Button type="submit">
//           Block Single Date
//         </Button>
//       </form>

//       <Box sx={{ padding: 3 }}>
//         <Typography variant="h4" component="h2" gutterBottom>
//           Block or Unblock Date Ranges
//         </Typography>
//         <Space direction="vertical" size={12}>
//           <RangePicker disabledDate={disableDates} onChange={handleRangeChange} value={selectedRange} />
//         </Space>
//         <Box sx={{ marginTop: 2 }}>
//           <Button variant="contained" color="primary" onClick={handleBlockDateRange} sx={{ marginRight: 2 }}>
//             Block Date Range
//           </Button>
//           <Button variant="contained" color="secondary" onClick={handleUnblockDateRange}>
//             Unblock Date Range
//           </Button>
//         </Box>
//         <Box sx={{ marginTop: 3 }}>
//           <Typography variant="h6" component="h3">
//             Blocked Dates:
//           </Typography>
//           <List>
//             {blockedDates.map((date) => (
//               <ListItem key={date}>
//                 <ListItemText primary={date} />
//                 <Button variant="contained" color="secondary" onClick={() => handleUnblockDate(date)}>
//                   Remove
//                 </Button>
//               </ListItem>
//             ))}
//           </List>
//         </Box>
//       </Box>
//     </>
//   );
// };

// export default AdminDateSelector;


import React, { useState, useEffect } from 'react';
import { DatePicker, Space } from 'antd';
import { Button, Typography, Box, List, ListItem, ListItemText } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { addBlockedDateRangeAsync, removeBlockedDateRangeAsync, fetchBlockedDatesAsync, customDateBlock } from '../../stateManager/reducers/dateslice';
import dayjs from 'dayjs';
import axios from '../../config/axios';

const { RangePicker } = DatePicker;

const AdminDateSelector = () => {
  // State
  const [selectedRange, setSelectedRange] = useState([]);
  const [customDate, setCustomDate] = useState('');
  
  // Redux
  const blockedDates = useSelector((state) => state.datePicker.blockedDates);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchBlockedDatesAsync());
  }, [dispatch]);

  const handleRangeChange = (dates) => {
    setSelectedRange(dates);
  };

  const handleBlockDateRange = () => {
    if (selectedRange && selectedRange.length === 2) {
      const [start, end] = selectedRange;
      dispatch(addBlockedDateRangeAsync({ start: start.format("DD-MM-YYYY"), end: end.format("DD-MM-YYYY") }));
      setSelectedRange([]);
    }
  };

  const handleUnblockDateRange = () => {
    if (selectedRange && selectedRange.length === 2) {
      const [start, end] = selectedRange;
      dispatch(removeBlockedDateRangeAsync({ start: start.format("DD-MM-YYYY"), end: end.format("DD-MM-YYYY") }));
      setSelectedRange([]);
    }
  };

  const handleUnblockDate = async (date) => {
    try {
      const DeleteDate_response = await axios.delete(`/blocked-dates/${date}`);
      dispatch(fetchBlockedDatesAsync()); // Refresh the blocked dates after deletion
    } catch (error) {
      console.error('Error unblocking date:', error);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission

    if (customDate.trim() !== "") {
      dispatch(customDateBlock({ date: customDate.trim() }));
      setCustomDate(''); // Clear input after dispatching
    } else {
      // Handle case where date is empty (optional)
      console.log("Please enter a valid date.");
    }
  };

  const handleInputChange = (event) => {
    setCustomDate(event.target.value); // Update state with user input
  };

  const disableDates = (currentDate) => {
    if (!currentDate) return false;
    // Disable dates before today 
    return currentDate.isBefore(dayjs().startOf('day'));
  };

  const isBlockedDate = (date) => {
    return blockedDates.includes(date.format("DD-MM-YYYY"));
  };

  const cellRender = (currentDate, today) => {
    const style = {};
    if (isBlockedDate(currentDate)) {
      style.backgroundColor = '#ff4d4f'; // Highlight blocked dates with red color
      style.color = '#fff';
    }
    return (
      <div className="ant-picker-cell-inner" style={style}>
        {currentDate.date()}
      </div>
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            id="customDate"
            aria-describedby="customDateHelp"
            placeholder="Enter custom date"
            value={customDate}
            onChange={handleInputChange}
          />
        </div>
        <Button type="submit" variant="contained" color="primary">
          Block Single Date
        </Button>
      </form>

      <Box sx={{ padding: 3 }}>
        <Typography variant="h4" component="h2" gutterBottom>
          Block or Unblock Date Ranges
        </Typography>
        <Space direction="vertical" size={12}>
          <RangePicker
            disabledDate={disableDates}
            onChange={handleRangeChange}
            value={selectedRange}
            cellRender={cellRender}
          />
        </Space>
        <Box sx={{ marginTop: 2 }}>
          <Button variant="contained" color="primary" onClick={handleBlockDateRange} sx={{ marginRight: 2 ,marginBottom:2 }}>
            Block Date Range
          </Button>
          <Button variant="contained" color="secondary" onClick={handleUnblockDateRange}>
            Unblock Date Range
          </Button>
        </Box>
        <Box sx={{ marginTop: 3 }}>
          <Typography variant="h6" component="h3">
            Blocked Dates:
          </Typography>
          <List>
            {blockedDates.map((date) => (
              <ListItem key={date}>
                <ListItemText primary={date} />
                <Button variant="contained" color="secondary" onClick={() => handleUnblockDate(date)}>
                  Remove
                </Button>
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    </>
  );
};

export default AdminDateSelector;
