import React, { useState, useEffect } from "react";
import "../style/admin_dashboard.css";
import axios from "../../config/axios";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  changeAvailableRooms,
  fetchAvailableRooms 
} from "../../stateManager/reducers/availablityslice";
// Components
import PendingBooking from "../admin_components/dashboard_booking";
import BlockDate from "../admin_components/dashboard_blockdate";
import RoomAndRates from "../admin_components/dashboard_rooms_rates";
import { CiEdit } from "react-icons/ci";
import { Button, Modal, Box, TextField ,Tooltip  } from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
function getDate() {
  const today = new Date();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const date = today.getDate();

  let hours = today.getHours();
  const minutes = today.getMinutes().toString().padStart(2, '0');
  const seconds = today.getSeconds().toString().padStart(2, '0');

  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  const showTime = `${hours}:${minutes}:${seconds} ${ampm}`;

  return `${date}/${month}/${year} , ${showTime}`;
}


function Dashboard() {
  // Redux
  const availableRooms = useSelector((state) => state.availableSlice.availableRooms
  );
  const allowedAdultsMultipleOf = useSelector((state) => state.availableSlice.allowedAdultsMultipleOf
  );
  const dispatch = useDispatch();

//   get rooms value from database 
  useEffect(() => {
    dispatch(fetchAvailableRooms());
}, [dispatch]);

  // State variables
  const [isChange, setIsChange] = useState(false);
  const [allBookings, setAllBookings] = useState([]);
  const [dashboardData, setDashboardData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [editFields, setEditFields] = useState({});
  const [currentDate] = useState(getDate());

  // Fetch data from the backend
  const fetchData = async () => {
    try {
      const bookingResponse = await axios.get("/bookings");
      const dashboardResponse = await axios.get("/dashboard");
      setDashboardData(dashboardResponse.data);
      setAllBookings(bookingResponse.data);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Handle room increment and decrement
  const incRoom = () => {
    if (availableRooms < 10) {
      setIsChange(true);
      dispatch(changeAvailableRooms(availableRooms + 1));
    }
  };

  const decRoom = () => {
    if (availableRooms > 0) {
      setIsChange(true);
      dispatch(changeAvailableRooms(availableRooms- 1));
    }
  };

  // Save changes to the backend of availableRooms
  const saveChanges = async () => {
    try {
        await axios.put("/dashboard", {
            availableRooms: availableRooms,
            lastUpdate:currentDate
        });
        setIsChange(false);
        fetchData();
    } catch (error) {
        console.error("Error saving changes:", error);
    }
   
  };
//   cancel changes to the backend of availableRooms
  const cancelChanges = async () => {
    dispatch(fetchAvailableRooms());
    setIsChange(false);
   
  };

//   handle availableRooms data change to state manager
  const handleRoomChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (value > 0 && value < 11) {
      setIsChange(true);
      dispatch(changeAvailableRooms(value));
    }
  };
  const handleAdultNumberchange = (e) => {
    // for only show purpose 
    // to neglect errors 
  };


  // Edit modal handlers
  const handleEdit = (fields) => {
    setEditFields(fields);
    setEditMode(true);
  };

  // tell me you don't know ! what it does 
  const handleEditClose = () => {
    setEditMode(false);
    setEditFields({});
  };

  // on change model call this 
  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditFields((prevFields) => (
        { ...prevFields, [name]: value }
    ));
  };
  // modal confirm call this 
  const handleEditSave = async () => {
    try {
    const updatedEditFields = { ...editFields, lastUpdate: currentDate };
      await axios.put(`/dashboard`, updatedEditFields);

      // Update local state (if necessary)
      setDashboardData((prevData) =>
        prevData.map((item) => ({ ...item, ...updatedEditFields }))
      );

      setEditMode(false);
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading booking data</p>;

  return (
    <div className="flex-center-col big-daddy">
      {isChange && (
        <div className="flex-center admin-save-changes">
              {/* cancel button  */}
              <Button
            className="flex-center-row  no-outline mr-3"
            onClick={cancelChanges}
            variant="contained"
            color="error"
          >
            cancel
          </Button>

          {/* save button  */}
          <Button
            className="flex-center-row save-btn no-outline"
            onClick={saveChanges}
            variant="contained"
          >
            Save Changes
          </Button>
        

        </div>
      )}
      <br />
      <br />
      <div className="super-upper-container">
      <div className="container00  flex-center-col">
        <h5>
        <Tooltip title={dashboardData[0].lastUpdate} placement="top-start">
              <span> Rooms and Rates: </span>
              </Tooltip>
        </h5>
        <div className="container00-wrapper">
         <RoomAndRates/>
        </div>

      </div>

      </div>

      <div className="flex-center-row upper-container ">
      

        <div className="container01 admin-dashboard-partition-container flex-center-col">
          <span style={{ width: "15rem" }}>
            <h5>
            <Tooltip title={dashboardData[0].lastUpdate} placement="top-start">
              <span> Contact details: </span>
            
              </Tooltip>
              <span
                className="mx-3 pointer"
                onClick={() =>
                  handleEdit({
                    contact: dashboardData[0].contact,
                    address: dashboardData[0].address,
                    email: dashboardData[0].email,
                  })
                }
              >
                <CiEdit />
              </span>
            </h5>
          </span>
          <div className="container01-wrapper">

            <div className="container01-wrapper-contact">
              <span style={{ fontStyle: "italic", fontWeight: "bold",marginRight:'1rem' }}>phone:</span>
              <span>{dashboardData[0].contact}</span>
            </div>

            <div className="container01-wrapper-address">
              <span style={{ fontStyle: "italic", fontWeight: "bold" ,marginRight:'1rem' }}>address:</span>
              <span>{dashboardData[0].address}</span>
            </div>

              <div className="container01-wrapper-email">
              <span style={{ fontStyle: "italic", fontWeight: "bold" ,marginRight:'1rem' }}>email:</span>
              <span>{dashboardData[0].email}</span>
            </div>
          </div>
        </div>
        <div className="container02 admin-dashboard-partition-container flex-center-col">
          <span style={{ width: "15rem" }}>
            <h5>
            <Tooltip title={dashboardData[0].lastUpdate} placement="top-start">
             <span> Editable strings: </span> 
              </Tooltip>
              <span
                className="mx-3 pointer"
                onClick={() =>
                  handleEdit({
                    copyright: dashboardData[0].copyright,
                    bookingCheckboxString:
                      dashboardData[0].bookingCheckboxString,
                  })
                }
              >
                <CiEdit />
              </span>
            </h5>
          </span>
          <div className="container03-copyright">
            <span style={{ fontStyle: "italic", fontWeight: "bold",marginRight:'1rem'  }}>
              copyright:
            </span>
            <span>{dashboardData[0].copyright}</span>
          </div>
          <div className="container03-bookingCheckbox">
            <span style={{ fontStyle: "italic", fontWeight: "bold",marginRight:'1rem'  }}>
              booking-checkbox-string:
            </span>
            <span>{dashboardData[0].bookingCheckboxString}</span>
          </div>
        </div>
      </div>

                
      <div className="flex-center-row middle-container">     
        <div className="container03 admin-dashboard-partition-container flex-center-col">
        <Tooltip title={dashboardData[0].lastUpdate} placement="top-start">
          <span>Total Rooms Available</span>
          </Tooltip>
          <div className="flex-center-row">
            <Button
              className="flex-center-row green-btn btnincdic"
              color="secondary"
              onClick={decRoom}
            >
              -
            </Button>
            <input
              className="flex-center"
              style={{ width: "4rem", height: "2rem" }}
              min={0}
              max={10}
              value={availableRooms}
              onChange={handleRoomChange}
              type="number"
            />
            <Button
              className="flex-center-row green-btn btnincdic"
              color="secondary"
              onClick={incRoom}
            >
              +
            </Button>
          </div>
          <br />
          <br />
          <Tooltip title={dashboardData[0].lastUpdate} placement="top-start">
          <span>Total Adults Allowed</span>
          </Tooltip>
          <div className="flex-center-row">
            <input
              className="flex-center"
              style={{ width: "4rem", height: "2rem" }}
              min={0}
              max={100}
              value={allowedAdultsMultipleOf * availableRooms}
              onChange={handleAdultNumberchange}
              type="number"
            />
           
          </div>
        </div>
        <div className=" admin-dashboard-partition-container container04 flex-center-col">
        <Accordion className="admin-dashboard-accordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Block/unBlock Dates
        </AccordionSummary>
        <AccordionDetails>
        <BlockDate/>  
        </AccordionDetails>
      </Accordion>
        </div>
      </div>

      <div className="lower-container mt-3">
        <h4>Pending bookings:</h4>
        <div className="display-booking-data">
          {!loading && <PendingBooking allBookings={allBookings} fechData={()=>{ fetchData()}} />}
          {loading && <p>Loading...</p>}
          {error && <p>Error loading bookings: {error.message}</p>}
        </div>
      </div>

      {/* edit pop up modal  */}
      <Modal open={editMode} onClose={handleEditClose}>
        <Box className="dashboard-modal-box">
          <h2>Edit Fields</h2>
          {Object.keys(editFields).map((key) => (
            <TextField
              key={key}
              name={key}
              label={key}
              variant="outlined"
              fullWidth
              value={editFields[key]}
              onChange={handleEditChange}
              margin="normal"
            />
          ))}
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleEditClose}
              style={{ marginRight: "10px" }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleEditSave}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default Dashboard;
