import React from 'react'
import { RxCross1 } from "react-icons/rx";
import "../style/popup.css"

function Popup_alert({Open, Close, Message}) {


    if(!Open){
        return null
    }

  return (
    <>
      <div className="alert-container">
        <div className="alert-wrapper text-box-content">
            <span className='message'>{Message} </span>
            <span className='close_popup pointer' onClick={Close}>{<RxCross1/>}</span>
        </div>


      </div>

    </>
  )
}

export default Popup_alert
