import React, { useState } from 'react';
import "./style/admin.css"
import { useAuth } from '../config/AuthContext';
import {useNavigate } from "react-router-dom";
// components 
import Navbar from "../components/sidepagesnavbar"
import Home from "./pages/admin_homepage"
import Gallery from "./pages/admin_gallery"
import Dashboard from "./pages/admin_dashboard"
import BookingManagement from "./pages/admin_bookings"
import ContactUs from "./pages/admin_contactUs"

// icons 
import { RxCross1 } from "react-icons/rx";
import { RxHamburgerMenu } from "react-icons/rx";
function Admin() {
  const [activeTab, setActiveTab] = useState('DashBoard');//the first tab you see at dashboard
  const [showNav, setshowNav] = useState(false);
  let navigate = useNavigate(); //to redirect 
  // for logout admin 
  const {logout } = useAuth();

  const handleTabChange = (item) => {
    setActiveTab(item);
  }; 
  
  const closeNav = (e) => {
    e.nativeEvent.preventDefault()
    setshowNav(!showNav);
  };

  const preventDefaults = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };

  const handleLogout = () => {
    logout()
    navigate("/login");

  };
  return (
    <div style={{width:'100%' } }  >
      <div className="admin-upper-bar">
      <Navbar />

      </div>
      <div className="admin-ham" onClick={closeNav}>

      <RxHamburgerMenu />
      </div>
      <br />
      <div className="admin-container" onClick={preventDefaults} >
        {showNav && 
        <div className="admin-side-navbar">
          <div className="admin-cross pointer" onClick={closeNav}>

          <RxCross1 />
          </div>
        <ul className="inner-wrapper">
         
          {['DashBoard', 'HomePage', 'Gallery', 'Bookings','Contact Us'].map((tab, index) => (
            <li key={index} className='pointer' onClick={() => handleTabChange(tab)}>
              {tab}
            </li>
          ))}

        <li className='dashboard-logout-bottom pointer' onClick={handleLogout}><span>logout</span></li>
        </ul>
          
        </div>
        }
      </div>
      <div className='admin-content-limit' onClick={()=>setshowNav(false)}>
        <h3 className='custom-design'>{activeTab}</h3>
        <br />
        <br />
        {activeTab === 'DashBoard' && <Dashboard/>}
        {activeTab === 'HomePage' && <Home/>}
        {activeTab === 'Gallery' && <Gallery/>}
        {activeTab === 'Bookings' && <BookingManagement/>}
        {activeTab === 'Contact Us' && <ContactUs/>}
      </div>
    </div>
  );
}

export default Admin;
