import React, { useEffect } from 'react'
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux'
import { from, to, daydiff ,fetchBlockedDatesAsync } from '../stateManager/reducers/dateslice'
// Import dayjs plugins
const isSameOrBefore = require('dayjs/plugin/isSameOrBefore');
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter');
// Extend dayjs with the plugins
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

function Datepicker({ placeholder = "select date", value }) {
  const dater = useSelector((state) => state.datePicker.value)
  const blockedDates = useSelector((state) => state.datePicker.blockedDates)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchBlockedDatesAsync());
  }, [dispatch]);


  const handleDateChange = (date) => {
    if (date) {
      let formattedDate = date.format("DD-MM-YYYY");
      if (value === "to") {
        dispatch(to(formattedDate));
        dispatch(daydiff());
      } else if (value === "from") {
        dispatch(from(formattedDate));
        dispatch(daydiff());
      }

      // Check if the selected date range overlaps with any blocked dates
      const [startDate, endDate] = value === "to" ? [dater[0], formattedDate] : [formattedDate, dater[1]];
      if (startDate && endDate) {
        const start = dayjs(startDate, "DD-MM-YYYY");
        const end = dayjs(endDate, "DD-MM-YYYY");
        
        for (let i = 0; i < blockedDates.length; i++) {
          const blockedDate = dayjs(blockedDates[i], "DD-MM-YYYY");
          if (blockedDate.isSameOrAfter(start) && blockedDate.isSameOrBefore(end)) {
            alert("The selected date range overlaps with blocked dates.");//add some good feedback
            break;
          }
        }
      }
    }
  };
  const disableDates = (currentDate) => {
    if (!currentDate) return false;

    // Disable dates before today and blocked dates
    return currentDate.isBefore(dayjs().startOf('day')) ||
           blockedDates.includes(currentDate.format("DD-MM-YYYY"));
  };

;

  return (
    <div>
      <DatePicker 
        disabledDate={disableDates}
        className='custom' 
        format={"DD-MM-YYYY"}  
        onChange={handleDateChange} 
        placeholder={placeholder} 
      />
    </div>
  )
}

export default Datepicker
