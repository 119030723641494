import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, Button, IconButton, Snackbar } from '@mui/material';
import { ContentCopy } from '@mui/icons-material';
import { CopyToClipboard } from 'react-copy-to-clipboard';


const ConfirmationModal = ({ open, handleClose, bookingDetails }) => {
    // for copy functionality 
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [textToCopy, setTextToCopy] = useState(); // The text you want to copy
    
    //set bookingId for copy functionality
    useEffect(() => {
        if (bookingDetails) {
            setTextToCopy(bookingDetails.bookingId);
        }
    }, [bookingDetails]);
    
    // show that little notice on bottom after copy bookingId
    const onCopyText = () => {
        setSnackbarOpen(true);
    };

    // close that little notice on bottom after copy bookingId
    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    if (!bookingDetails) return null; // if bookingDetails is null or undefined

     // get booking details 
     const {
        bookingId,
        firstname,
        lastname,
        phone,
        email,
        totalPersons,
        totalRooms,
        checkIn,
        checkOut,
        roomType,
        totalNight,
        payOnline,
        totalPayableAmount
    } = bookingDetails;


    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 350,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2,
                        outline: 0,
                    }}
                >
                    <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
                        🎉 Booking Confirmation 🎉
                    </Typography>

                    <Typography id="modal-description" sx={{ mt: 2, textAlign: 'left' }}>
                        <strong>Booking ID:</strong> {bookingId}
                        
                        <CopyToClipboard text={textToCopy} onCopy={onCopyText}>
                            <IconButton size="small" sx={{ ml: 1 }}>
                                <ContentCopy fontSize="small" />
                            </IconButton>
                        </CopyToClipboard>

                        <br />
                        <br />
                        <span>
                            Dear {firstname} {lastname},
                        </span> <br />
                        <span>
                            Your booking has been confirmed for {totalRooms} {roomType} {totalRooms>1 ?"rooms" :"room"}. Please check your email for further details.
                        </span> <br />
                        <span></span>
                        <strong>Check-In:</strong> {checkIn}<br />
                        <strong>Check-Out:</strong> {checkOut}<br />
                        <strong>Total Amount:</strong> Rs {totalPayableAmount}/- {!payOnline ? <span style={{color:"orange"}}>(due)</span>: null}<br />
                        <br />
                        <br />
                        <span>
                            Regards
                        </span> <br />
                        <span>
                            Rathore Heritage Haveli Alsisar
                        </span> <br />
                    </Typography>
                    <Button onClick={handleClose} sx={{
                        mt: 3,
                        bgcolor: "green",
                        "&:hover": {
                            bgcolor: "#388e3c", //light green
                        },
                    }} variant="contained" color="primary">
                        Close
                    </Button>
                </Box>
            </Modal>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                message="Booking ID copied to clipboard"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            />
        </>
    );
};

export default ConfirmationModal;
