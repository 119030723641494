import React, { useState } from 'react';
import { useAuth } from '../config/AuthContext';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Box,
  Typography,
  Container,
  Alert,
  CircularProgress,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme();

const Register = () => {
  const [admin, setAdmin] = useState('');
  const [key, setKey] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const { register, logout } = useAuth();
  let navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');
    setSuccess('');
    setLoading(true);
    try {
      await register(admin, key);
      setSuccess('Registration successful. Please log in.');
    } catch (error) {
      if (error.response){
        setError(error.response.data.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = () => {
    logout();
    navigate('/rhh-admin/login');
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar> */}
          <Avatar sx={{ m: 1, width: 72, height: 72 }}>
            <img
              className='center-image'
              src="https://ik.imagekit.io/transferRRHphotos/heritage/Gallery/IMG_1237.jpg?updatedAt=1719564711835"
              alt="img"
              style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '50%' }}
            />
          </Avatar>
          <Typography component="h1" variant="h5">
            Register
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            {error && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error}
              </Alert>
            )}
            {success && (
              <Alert severity="success" sx={{ mb: 2 }}>
                {success}
              </Alert>
            )}

            <TextField
              margin="normal"
              required
              fullWidth
              id="admin"
              label="Admin"
              name="admin"
              autoComplete="admin"
              autoFocus
              value={admin}
              onChange={(e) => setAdmin(e.target.value)}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#D2BD7F',
                  },
                  '&:hover fieldset': {
                    borderColor: '#C0A76E',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#C0A76E',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: '#D2BD7F',
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#C0A76E',
                },
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="key"
              label="Key"
              type="password"
              id="key"
              autoComplete="current-password"
              value={key}
              onChange={(e) => setKey(e.target.value)}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#D2BD7F',
                  },
                  '&:hover fieldset': {
                    borderColor: '#C0A76E',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#C0A76E',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: '#D2BD7F',
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#C0A76E',
                },
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, backgroundColor: "#D2BD7F", '&:hover': { backgroundColor: "#C0A76E" } }}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} sx={{color:"#D2BD7F"}} /> : 'Save Changes'}
            </Button>
          </Box>
        </Box>
        <div className="flex-center">
          
        <Box mt={8} mr={4} display="flex" justifyContent="center">
          <Button  sx={{ color: "#C0A76E"  }} onClick={handleLogout}>Logout</Button>
        </Box>       
         
         <Box mt={8} display="flex" justifyContent="center">
          <Button  sx={{ color: "#C0A76E"}} onClick={()=>{navigate('/rhh-admin/dashboard')}}>Dashboard</Button>
        </Box>
          </div> 
      </Container>
    </ThemeProvider>
  );
};

export default Register;
