import { createSlice } from '@reduxjs/toolkit'



export const paySlice = createSlice({
    name: 'paySlice',
    initialState: {
    payOnline:true,
    },
    reducers: {
    payOnline: (state,action) => {
    
        state.payOnline =  action.payload
    },   

    },
    })

export const { payOnline ,roomType} = paySlice.actions

export default paySlice.reducer