import { useEffect, useState } from 'react';
import './App.css';
import Homepage from './pages/homepage';
import Gallerypage from './pages/gallerypage';
import Contactpage from './pages/contactpage';
import Bookformpage from './pages/bookingformpage';
import axios from './config/axios';
import Admin from './admin/admin';
import {BrowserRouter,Routes,Route,} from "react-router-dom";
// auth 
import { AuthProvider } from './config/AuthContext';
import PrivateRoute from './config/PrivateRoute';
// component 
import Loading from './components/loading'
import Login from './components/Login';
import Register from './components/Register';

// speed Insights for vercel 
import { SpeedInsights } from '@vercel/speed-insights/react';



function App() {
  const [galleryData, setGalleryData] = useState([]);
  const [homepageData, sethomepageData] = useState([]);
  const [ContactDataFROMdashboard, setContactDataFROMdashboard] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // gallery data feching 
        const gallery_response = await axios.get('/gallery'); 
        setGalleryData(gallery_response.data);  
        // homepage data feching 
        const homepage_response = await axios.get('/homedata'); 
        sethomepageData(homepage_response.data);      
        // dashboard data feching 
        const dashboard_response = await axios.get('/dashboard'); 
        setContactDataFROMdashboard(dashboard_response.data);

        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <Loading/>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }


  // return (
  //   <div className="App">
  //   <BrowserRouter future={{ v7_startTransition: true }}>

  //   <Routes>
  

  //     <Route path='/' element={<Homepage homepageData={homepageData} />}/>
  //     <Route path='/gallery' element={<Gallerypage ImagesData={galleryData} Title="Gallery"/>}/>
  //     <Route path='/rooms' element={<Gallerypage ImagesData={galleryData.filter(item => item.type === 'Room Gallery')} Title="Room Gallery"/>}/>
  //     <Route path='/restaurant' element={<Gallerypage ImagesData={galleryData.filter(item => item.type === 'Food Gallery')} Title="Food Gallery"/>}/>
  //     <Route path='/contact' element={<Contactpage/>}/>
  //     <Route path='/book' element={<Bookformpage/>}/>
  //     <Route path='/book/payment' element={<Paymentpage/>}/>
  //     <Route path='/paytest' element={<Paytest/>}/>
  //     {/* <Route path='/book/payment/create-checkout-session' element={<Testpayment/>}/> */}
 
  //     <Route path='/dashboard' element={<Admin/>}/>
      
  
        

  //   </Routes>
    
  //   </BrowserRouter>
  //   </div>
  // );
  return (
    <div className="App">
      {/* for vercel  */}
       <SpeedInsights />
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route path='/' element={<Homepage homepageData={homepageData} contactDetails={ContactDataFROMdashboard} ConfirmBookingDetails={null}/>} />

            <Route path='/gallery' element={<Gallerypage ImagesData={galleryData.filter(item => item.type ==="Main Gallery")} Title="Gallery" contactDetails={ContactDataFROMdashboard} />} />

            <Route path='/rooms' element={<Gallerypage ImagesData={galleryData.filter(item => item.type === 'Room Gallery')} Title="Room Gallery" contactDetails={ContactDataFROMdashboard}  />} />

            <Route path='/restaurant' element={<Gallerypage ImagesData={galleryData.filter(item => item.type === 'Food Gallery')} Title="Food Gallery" contactDetails={ContactDataFROMdashboard} />} />

            <Route path='/places' element={<Gallerypage ImagesData={galleryData.filter(item => item.type === 'Place Gallery')} Title="Place Gallery" contactDetails={ContactDataFROMdashboard} />} />

            <Route path='/contact' element={<Contactpage contactDetails={ContactDataFROMdashboard} />} />

            <Route path='/book-now' element={<Bookformpage contactDetails={ContactDataFROMdashboard}  homepageData={homepageData} />} />

            <Route path='/rhh-admin/login' element={<Login />} />

            <Route element={<PrivateRoute />}>
              <Route path="/rhh-admin/dashboard" element={<Admin />} />
              <Route path='/rhh-admin/dashboard/changeadmin' element={<Register />} />
              
            </Route>
            

          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
