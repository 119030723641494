// import React, { useState, useEffect } from 'react';
// import axios from '../../config/axios';
// import { Typography, TextField, Button, Grid, Paper } from '@mui/material'; // Import Material-UI components
// import DeleteIcon from '@mui/icons-material/Delete'; // Icon for delete button
// function dashboard_rooms_rates() {

//     // stats 
// const [availableRoomsTypeWithRates, setAvailableRoomsTypeWithRates] = useState({});

// useEffect(() => {
//   // Fetch initial data
//   const fetchData = async () => {
//     try {
//       const response = await axios.get('/dashboard'); // Assuming your endpoint
//       setAvailableRoomsTypeWithRates(response.data[0].availableRoomsTypeWithRates);
//     } catch (error) {
//       console.error("Error fetching data", error);
//     }
//   };

//   fetchData();
// }, []);


// const handleDelete = async (key) => {
//   try {
//     const response = await axios.delete(`/dashboard/availableRoomsTypeWithRates/${key}`);
//     console.log(response.data) // Assuming your endpoint
//     setAvailableRoomsTypeWithRates(prev => {
//       const newData = { ...prev };
//       delete newData[key];
//       return newData;
//     });
//   } catch (error) {
//     console.error("Error deleting data", error);
//   }
// };

// const handleUpdateavailableRoomsTypeWithRates = async (newRoomType , newRates) => {
//   try {
//     const response = await axios.put('/dashboard/availableRoomsTypeWithRates', { key : newRoomType, value : newRates});
//     setAvailableRoomsTypeWithRates(response.data.availableRoomsTypeWithRates);
//   } catch (error) {
//     console.error("Error updating room type", error);
//   }
// };



//   return (
//     <div>
//     <div className="container00-wrapper">
//       <div className="container00-wrapper-container">
//         <div className="availableRoomsTypeWithRates-display">
//           {Object.entries(availableRoomsTypeWithRates).map(([key, value]) => (
//             <div key={key}>
//               <span>{key}: {value}</span>
//               <button onClick={() => handleDelete(key)}>Delete</button>
//             </div>
//           ))}
//         </div>
        
//         <div className="roomType-maker">
//           <input type="text" placeholder="New Room Type" id="newRoomType" />
//           <input type="text" placeholder="New Rates" id="newRates" />
//           <button onClick={() => {
//             const newRoomType = document.getElementById('newRoomType').value;
//             const newRates = document.getElementById('newRates').value;
//             handleUpdateavailableRoomsTypeWithRates(newRoomType , newRates);
//           }}>
//             Update Room and Rates 
//           </button>
//         </div>
        

//       </div>
//     </div>

      
//     </div>
//   )
// }

// export default dashboard_rooms_rates

import React, { useState, useEffect } from 'react';
import axios from '../../config/axios';
// components 
import ComfirmationPopUP from "./confirmation_modal_popup";
// Import Material-UI components
import { Typography, TextField, Button, Grid, Paper, Container } from '@mui/material'; 
import DeleteIcon from '@mui/icons-material/Delete'; // Icon for delete button
import { FiRefreshCcw } from "react-icons/fi";
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';    


function DashboardRoomsRates() {
  const [availableRoomsTypeWithRates, setAvailableRoomsTypeWithRates] = useState({});
  const [newRoomType, setNewRoomType] = useState('');
  const [newRates, setNewRates] = useState('');
  const [ShowModelPopup, setShowModelPopup] = useState(false);

// Fetch initial data
const fetchData = async () => {
    try {
        const response = await axios.get('/dashboard'); // Assuming your endpoint
        setAvailableRoomsTypeWithRates(response.data[0].availableRoomsTypeWithRates);
    } catch (error) {
        console.error("Error fetching data", error);
    }
    };


  useEffect(() => {
    fetchData();
  }, []);



  const handleDelete = async (key) => {
    try {
      const response = await axios.delete(`/dashboard/availableRoomsTypeWithRates/${key}`);
      setAvailableRoomsTypeWithRates(prev => {
        const newData = { ...prev };
        delete newData[key];
        return newData;
      });
      if(key==='reset'){
        fetchData();
      }
    } catch (error) {
      console.error("Error deleting data", error);
    }
  };

  const handleUpdateavailableRoomsTypeWithRates = async (newRoomType, newRates) => {
    try {
      const response = await axios.put('/dashboard/availableRoomsTypeWithRates', { key: newRoomType, value: newRates });
      setAvailableRoomsTypeWithRates(response.data.availableRoomsTypeWithRates);
      setNewRoomType(''); // Clear input fields after update
      setNewRates(''); // Clear input fields after update
    } catch (error) {
      console.error("Error updating room type", error);
    }
  };

  const handleUpdate = () => {
    const newRoomType = document.getElementById('newRoomType').value;
    const newRates = document.getElementById('newRates').value;
    handleUpdateavailableRoomsTypeWithRates(newRoomType, newRates);
  };

  const handleReset = (key) => {
    // handleDelete("reset")
    setShowModelPopup(true);
  };

  return (
    <>
     {/* show, onClose, onConfirm, title, message */}
        <ComfirmationPopUP show={ShowModelPopup} onClose={()=>{setShowModelPopup(false)}} onConfirm={()=>{handleDelete("reset"); setShowModelPopup(false);}} title={"Confirm Reset"} message={"Are you sure to reset all Rates and Rooms?"}/>
       <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          Edit RoomType and RoomRates
        </AccordionSummary>
        <AccordionDetails>

        <Container maxWidth="lg"> {/* Use Container for better width management */}
      <div className="container00-wrapper">
        <div className="reset-roomtype-rates">
                     <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<FiRefreshCcw/>}
                    onClick={() => handleReset("reset")}
                    size="small"
                    sx={{ mt: 1 }} // Add margin top for spacing
                  >
                    reset
                  </Button>

        </div>

        <Grid container spacing={3} justifyContent="center">

          <Grid item xs={12} md={8} lg={6}> {/* Adjust column size based on screen width */}
            <Paper elevation={3} sx={{ p: 3 }}>
              <Typography variant="h5" gutterBottom className="availableRoomsTypeWithRates-display">
                Available Rooms Type with Rates
              </Typography>
              {Object.entries(availableRoomsTypeWithRates).map(([key, value]) => (
                <div key={key} style={{ marginBottom: '10px' }}>
                  <Typography variant="body1">
                    {key}: <b>{value}/- </b>
                  </Typography>
                  <Button
                    variant="contained"
                    color="error"
                    startIcon={<DeleteIcon />}
                    onClick={() => handleDelete(key)}
                    size="small"
                    sx={{ mt: 1 }} // Add margin top for spacing
                  >
                    Delete
                  </Button>
                </div>
              ))}
            </Paper>
          </Grid>

          <Grid item xs={12} md={4} lg={6}>
            <Paper elevation={3} sx={{ p: 3 }}>
              <Typography variant="h5" gutterBottom className="roomType-maker">
                Update Room Type and Rates
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                label="New Room Type"
                placeholder="Enter New Room Type"
                autoComplete="off"
                id="newRoomType"
                value={newRoomType}
                onChange={(e) => setNewRoomType(e.target.value)}
                style={{ marginBottom: '10px' }}
              />
              <TextField
                fullWidth
                variant="outlined"
                label="New Rates"
                placeholder="Enter New Rates"
                autoComplete="off"
                id="newRates"
                value={newRates}
                onChange={(e) => setNewRates(e.target.value)}
                style={{ marginBottom: '10px' }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpdate}
                fullWidth
                sx={{ mt: 2 }}
              >
                Update Room and Rates
              </Button>
            </Paper>
          </Grid>


        </Grid>
      </div>
        </Container>
        </AccordionDetails>
      </Accordion>
    


  </>
  );
}

export default DashboardRoomsRates;
